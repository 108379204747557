import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { EffectActions } from "@cloudinary/url-gen/actions/effect"
import { thumbnail } from "@cloudinary/url-gen/actions/resize"

interface CloudinaryImageProps {
  image: any,
  width?: number,
  height?: number,
  className?: string,
  effects?: EffectActions[],
}

export default function CloudinaryImage(props:CloudinaryImageProps): JSX.Element {
  const { image, width, height, className, effects } = props
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'enunicorn'
    }
  })

  const myImage = cld.image(image.public_id)
  if (width && height) {
    myImage.resize(thumbnail().width(width).height(height))
  }
  if (effects) {
    for (const effect of effects) {
      myImage.effect(effect)
    }
  }

  return (
    <AdvancedImage className={className} cldImg={myImage} />
  )
}
