const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  // authDomain: "zuno-ed09b.firebaseapp.com",
  authDomain: "enunicorn-e4e5f.firebaseapp.com",
  projectId: "enunicorn-e4e5f",
  storageBucket: "enunicorn-e4e5f.appspot.com",
  messagingSenderId: "147516725062",
  appId: "1:147516725062:web:61b5685ac7cc0fde63f747",
  measurementId: "G-J51GJP7FT0",
  databaseURL: 'https://enunicorn-e4e5f-default-rtdb.asia-southeast1.firebasedatabase.app',
};

export default config;
