import {ReactNode, useState} from "react"
import {Button, ButtonProps, Spinner} from "react-bootstrap"

interface SubmitButtonProps extends ButtonProps{
  children?: ReactNode,
  onClick?: any,
}

export default function SubmitButton(props: SubmitButtonProps): JSX.Element {
  const { children, onClick } = props
  const [submitted, setSubmitted] = useState<boolean>(true)

  const handleClick = async (e: any): Promise<void> => {
    if (!onClick) {
      return
    }
    setSubmitted(false)
    const callback = await onClick(e)
    setSubmitted(true)
    callback && callback()
  }

  return (
    <Button {...props} onClick={handleClick} disabled={!submitted || props.disabled}>
      <>
        {!submitted && <Spinner animation="border" size="sm" className="me-2" />}
        {children}
      </>
    </Button>
  )
}
