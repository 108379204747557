import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QRCodeCanvas } from 'qrcode.react';
import { get, getDatabase, ref, set } from 'firebase/database';
import { getAuth } from "firebase/auth";

interface QRCodesProps {
  id?: string,
  raffle?: any,
}

function QRCodes(props:QRCodesProps) {
  const { t } = useTranslation()
  const { id, raffle } = props
  const [ codes, setCodes ] = useState<any>([])
  const [ isSuperAdmin, setIsSuperAdmin ] = useState<any>(false)
  const [ showCodes, setShowCodes ] = useState<any>([])

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      if (!isSubscribed) {
        return
      }
      const auth = getAuth()
      setIsSuperAdmin(auth.currentUser && auth.currentUser.uid === '8ImYqwikfgOHN1tpS9rwXyiOxnk2')
      setCodes(Object.values(raffle?.codes ?? {}))
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [id, raffle])

  const generateCode = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleGenerateCodes = async () => {
    if (raffle.codesGenerated) {
      return
    }
    let count = 0
    while (count < raffle.quantity) {
      const code = generateCode(6)
      const exists = (await get(ref(getDatabase(), `keycapCodes/${code}`))).val()
      if (!exists) {
        count++
        await set(ref(getDatabase(), `keycapCodes/${code}`), id)
        await set(ref(getDatabase(), `raffles/${id}/codes/${code}`), {
          code,
          order: count,
          printed: false,
        })
      }
    }
    await set(ref(getDatabase(), `raffles/${id}/codesGenerated`), true)
  }

  const handleOneGenerateCode = async () => {
    let count = 0
    while (count < 1) {
      const code = generateCode(6)
      const exists = (await get(ref(getDatabase(), `keycapCodes/${code}`))).val()
      if (!exists) {
        count++
        await set(ref(getDatabase(), `keycapCodes/${code}`), id)
        await set(ref(getDatabase(), `raffles/${id}/codes/${code}`), {
          code,
          order: raffle.quantity,
          printed: false,
        })
      }
    }
    await set(ref(getDatabase(), `raffles/${id}/codesGenerated`), true)
  }

  const handlePrint = async (item: any) => {
    await set(ref(getDatabase(), `raffles/${id}/codes/${item.code}/printed`), !item.printed)
  }

  const handleShowCode = (code: string) => {
    setShowCodes({
      ...showCodes,
      [code]: !showCodes[code]
    })
  }

  return (
    <>
      <Table variant="dark" striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t('#')}</th>
            <th style={{width: '30%'}}>{t('Code')}</th>
            <th style={{width: '30%'}} className="text-center">{t('QR Code')}</th>
            <th className="text-end">{t('Action')}</th>
          </tr>
        </thead>
        <tbody>
          {!codes.length && (
            <tr>
              <td colSpan={4}>{t('Not Found')}</td>
            </tr>
          )}
          {!!codes.length && codes.sort((a: any, b: any) => a.order - b.order).map((item:any, index: number) => (
            <tr key={index}>
              <td>{item.order}</td>
              <td>{item.code}</td>
              <td className="text-center">
                <Button size="sm" variant="primary" onClick={() => handleShowCode(item.code)}>{t(showCodes[item.code] ? 'Hide' : 'Show')}</Button>
                {showCodes[item.code] && <div className="m-3"><QRCodeCanvas className="img-thumbnail" value={'https://enunicorn.com/authenticity/' + item.code} /></div>}
              </td>
              <td className="text-end">
                <a className="btn btn-primary btn-sm me-1" href={'https://enunicorn.com/authenticity/' + item.code} target="_blank" rel="noreferrer">Authenticity</a>
                {<Button size="sm" variant={item.printed ? 'success' : 'primary'} onClick={() => handlePrint(item)}>{t(item.printed ? 'Unmark' : 'Mark')}</Button>}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {!raffle.codesGenerated && <div className="mb-3">
        <Button onClick={handleGenerateCodes}>Generate QR Codes</Button>
      </div>}
      {raffle.codesGenerated && isSuperAdmin && <div className="mb-3">
        <Button onClick={handleOneGenerateCode}>Generate 1 QR Code</Button>
      </div>}
    </>
  )
}

export default QRCodes
