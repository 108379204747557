import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AdminLayout from './components/AdminLayout';
import Layout from './components/Layout';
import UserLayout from './components/UserLayout';
import { adminUids } from './configs';
import * as Raffles from './pages/admin/Raffles';
import * as GroupBuys from './pages/admin/GroupBuys';
import * as Products from './pages/admin/Products';
import * as Mails from './pages/admin/Mails';
import Home from './pages/Home';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Verify from './pages/Verify';
import RaffleDetails from './pages/RaffleDetails';
import Authenticity from './pages/Authenticity';
import RafflePayment from './pages/RafflePayment';
import PaymentExpiration from './pages/PaymentExpiration';
import ShippingAddress from './pages/ShippingAddress';
import RaffleProcessing from './pages/RaffleProcessing';
import Security from './pages/Security';
import PaymentCancel from './pages/PaymentCancel';
import PaymentSuccess from './pages/PaymentSuccess';

function App() {
  const navigate = useNavigate()
  const [user, setUser] = useState<any>()
  const [firebaseLoaded, setFirebaseLoaded] = useState<boolean>(false)

  useEffect(() => {
    let isSubscribed = true
    onAuthStateChanged(getAuth(), async (user) => {
      if (!isSubscribed) {
        return
      }
      setUser(user)
      setFirebaseLoaded(true)
    })
    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    if (user && !user?.emailVerified) {
      navigate('/verify-account')
    }
  }, [navigate, user])

  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const location = useLocation()
    if (!user) {
      return <Navigate to="/login" state={{ from: location }} replace />
    }
    return children
  }

  const RequireAdminRole = ({ children }: { children: JSX.Element }) => {
    if (!user || adminUids.indexOf(user?.uid) < 0) {
      return <NotFound />
    }
    return children
  }

  return (
    <>
      {firebaseLoaded && <div className="App">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<Home />} />
            <Route path="verify-account" element={<RequireAuth><Verify /></RequireAuth>} />
            <Route path="admin" element={<RequireAdminRole><AdminLayout /></RequireAdminRole>}>
              <Route path="raffles">
                <Route index element={<Raffles.List />} />
                <Route path="create" element={<Raffles.Create />} />
                <Route path="show/:id" element={<Raffles.Show />} />
                <Route path="edit/:id" element={<Raffles.Edit />} />
              </Route>
              <Route path="group-buys">
                <Route index element={<GroupBuys.List />} />
                <Route path="create" element={<GroupBuys.Create />} />
                <Route path="show/:id" element={<GroupBuys.Show />} />
                <Route path="edit/:id" element={<GroupBuys.Edit />} />
              </Route>
              <Route path="products">
                <Route index element={<Products.List />} />
                <Route path="create" element={<Products.Create />} />
                <Route path="show/:id" element={<Products.Show />} />
                <Route path="edit/:id" element={<Products.Edit />} />
              </Route>
              <Route path="mails">
                <Route index element={<Mails.List />} />
                <Route path="create" element={<Mails.Create />} />
                <Route path="edit/:id" element={<Mails.Edit />} />
              </Route>
            </Route>
            <Route path="authenticity">
              <Route index element={<Authenticity />} />
              <Route path=":code" element={<Authenticity />} />
            </Route>
            <Route path="settings" element={<RequireAuth><UserLayout /></RequireAuth>}>
              <Route index element={<Profile />} />
              <Route path="shipping-addresses" element={<ShippingAddress />} />
              <Route path="security" element={<Security />} />
            </Route>
            <Route path="processing-raffles" element={<RaffleProcessing />} />
            <Route path="raffles/:alias" element={<RaffleDetails />} />
            <Route path="raffles/:alias/orders/:orderId/payments" element={<RequireAuth><RafflePayment /></RequireAuth>} />
            <Route path="payment">
              <Route path="expiration" element={<PaymentExpiration/>} />
              <Route path="cancel" element={<PaymentCancel/>} />
              <Route path="success" element={<PaymentSuccess/>} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        <ToastContainer theme="colored"/>
      </div>}
    </>
  );
}

export default App;
