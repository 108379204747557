import * as Yup from 'yup'
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAuth } from 'firebase/auth';
import { get, getDatabase, ref, set } from 'firebase/database';
import { toast } from 'react-toastify';
import { countries } from 'country-code-lookup'
import SubmitButton from "./SubmitButton";

function ShippingAddressForm() {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    shippingName: Yup.string().required().label('Display Name'),
    shippingPhone: Yup.string().required().label('Phone'),
    shippingAddressLine1: Yup.string().required().label('Address Line 1'),
    shippingAddressLine2: Yup.string().optional().label('Address Line 2'),
    shippingCity: Yup.string().required().label('City'),
    shippingState: Yup.string().optional().label('State'),
    shippingCountry: Yup.string().required().label('Country'),
    shippingPostalCode: Yup.string().required().label('Postal Code'),
  })

  const [formData, setFormData] = useState({
    displayName: '',
    phone: '',
    address: '',
    country: '',
    shippingName: '',
    shippingPhone: '',
    shippingAddressLine1: '',
    shippingAddressLine2: '',
    shippingCity: '',
    shippingState: '',
    shippingCountry: '',
    shippingPostalCode: '',
  })
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user || !isSubscribed) {
        return
      }
      const dataSnapshot = await get(ref(getDatabase(), `users/${user.uid}`))
      if (!isSubscribed) {
        return
      }
      const profile = dataSnapshot.val()
      const data:any = {
        ...user,
        ...profile,
        shippingName: profile.shippingName ?? '',
        shippingPhone: profile.shippingPhone ?? '',
        shippingAddressLine1: profile.shippingAddressLine1 ?? '',
        shippingAddressLine2: profile.shippingAddressLine2 ?? '',
        shippingCity: profile.shippingCity ?? '',
        shippingState: profile.shippingState ?? '',
        shippingCountry: profile.shippingCountry ?? '',
        shippingPostalCode: profile.shippingPostalCode ?? '',
      }
      setFormData(data)
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [])

  const handleSetFormData = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleCopyInformation = (e:any) => {
    setFormData({
      ...formData,
      shippingName: formData.displayName,
      shippingPhone: formData.phone,
      shippingAddressLine1: formData.address,
      shippingCountry: formData.country,
    })
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    setErrors({})

    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err:any) {
      const errors:any = {}
      for (const error of err.inner) {
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }

    try {
      const data:any = { ...formData }
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) {
        throw new Error('Require login')
      }
      await set(ref(getDatabase(), `users/${user.uid}/shippingName`), data.shippingName)
      await set(ref(getDatabase(), `users/${user.uid}/shippingPhone`), data.shippingPhone)
      await set(ref(getDatabase(), `users/${user.uid}/shippingAddressLine1`), data.shippingAddressLine1)
      await set(ref(getDatabase(), `users/${user.uid}/shippingAddressLine2`), data.shippingAddressLine2 ?? '')
      await set(ref(getDatabase(), `users/${user.uid}/shippingCity`), data.shippingCity)
      await set(ref(getDatabase(), `users/${user.uid}/shippingState`), data.shippingState ?? '')
      await set(ref(getDatabase(), `users/${user.uid}/shippingCountry`), data.shippingCountry)
      await set(ref(getDatabase(), `users/${user.uid}/shippingPostalCode`), data.shippingPostalCode)
      toast(t('Update shipping address successful'), { type: 'success' })
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  return (
    <Form>
      <div className="text-warning">
        {t('The shipping address is important for Enunicorn to ship the product. Enunicorn will not be responsible when you use the wrong address and lose the products')}
      </div>
      <Form.Group className="mb-3" controlId="shippingName">
        <Form.Label>{t('Full Name')} <span className="text-danger">*</span></Form.Label>
        <Form.Control type="text" name="shippingName" value={formData.shippingName} onChange={handleSetFormData} placeholder={t('Full Name')} />
        {errors?.['shippingName']?.message && (<Form.Text className="text-danger">{t(errors?.['shippingName']?.message)}</Form.Text>)}
      </Form.Group>
      <Form.Group className="mb-3" controlId="shippingPhone">
        <Form.Label>{t('Phone')} <span className="text-danger">*</span></Form.Label>
        <Form.Control type="text" name="shippingPhone" value={formData.shippingPhone} onChange={handleSetFormData} placeholder={t('Phone')} />
        {errors?.['shippingPhone']?.message && (<Form.Text className="text-danger">{t(errors?.['shippingPhone']?.message)}</Form.Text>)}
      </Form.Group>
      <Form.Group className="mb-3" controlId="shippingAddressLine1">
        <Form.Label>{t('Address Line 1')} <span className="text-danger">*</span></Form.Label>
        <Form.Control type="text" name="shippingAddressLine1" value={formData.shippingAddressLine1} onChange={handleSetFormData} placeholder={t('Address Line 1')} />
        {errors?.['shippingAddressLine1']?.message && (<Form.Text className="text-danger">{t(errors?.['shippingAddressLine1']?.message)}</Form.Text>)}
      </Form.Group>
      <Form.Group className="mb-3" controlId="shippingAddressLine2">
        <Form.Label>{t('Address Line 2')}</Form.Label>
        <Form.Control type="text" name="shippingAddressLine2" value={formData.shippingAddressLine2} onChange={handleSetFormData} placeholder={t('Address Line 2')} />
        {errors?.['shippingAddressLine2']?.message && (<Form.Text className="text-danger">{t(errors?.['shippingAddressLine2']?.message)}</Form.Text>)}
      </Form.Group>
      <Form.Group className="mb-3" controlId="shippingCity">
        <Form.Label>{t('City')} <span className="text-danger">*</span></Form.Label>
        <Form.Control type="text" name="shippingCity" value={formData.shippingCity} onChange={handleSetFormData} placeholder={t('City')} />
        {errors?.['shippingCity']?.message && (<Form.Text className="text-danger">{t(errors?.['shippingCity']?.message)}</Form.Text>)}
      </Form.Group>
      <Form.Group className="mb-3" controlId="shippingState">
        <Form.Label>{t('State')}</Form.Label>
        <Form.Control type="text" name="shippingState" value={formData.shippingState} onChange={handleSetFormData} placeholder={t('State')} />
        {errors?.['shippingState']?.message && (<Form.Text className="text-danger">{t(errors?.['shippingState']?.message)}</Form.Text>)}
      </Form.Group>
      <Form.Group className="mb-3" controlId="shippingCountry">
        <Form.Label>{t('Country')} <span className="text-danger">*</span></Form.Label>
        <Form.Select name="shippingCountry" value={formData.shippingCountry} onChange={handleSetFormData}>
          <option value="">{t('-- Select Country --')}</option>
          {countries.filter(country => country.isoNo !== "581").map(country => (
            <option key={country.internet} value={country.internet.toLowerCase()}>{t(country.country)}</option>
          ))}
          <option value="others">{t('Others')}</option>
        </Form.Select>
        {errors?.['shippingCountry']?.message && (<Form.Text className="text-danger">{t(errors?.['shippingCountry']?.message)}</Form.Text>)}
      </Form.Group>
      <Form.Group className="mb-3" controlId="shippingPostalCode">
        <Form.Label>{t('Postal Code')} <span className="text-danger">*</span></Form.Label>
        <Form.Control type="text" name="shippingPostalCode" value={formData.shippingPostalCode} onChange={handleSetFormData} placeholder={t('Postal Code')} />
        {errors?.['shippingPostalCode']?.message && (<Form.Text className="text-danger">{t(errors?.['shippingPostalCode']?.message)}</Form.Text>)}
      </Form.Group>
      {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
      <div className="d-grid gap-2 mt-5">
        <div className="btn-group">
          <Button variant="dark" size="lg" type="button" onClick={handleCopyInformation}>
            {t('Use Info From Your Profile')}
          </Button>
          <SubmitButton variant="primary" size="lg" type="submit" onClick={handleSubmit}>
            {t('Update')}
          </SubmitButton>
        </div>
      </div>
    </Form>
  );
}

export default ShippingAddressForm;
