import { Col, Container, Nav, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { LinkContainer } from '../resolveJs';

function AdminLayout() {
  const { t } = useTranslation()

  return <>
    <div className="my-5">&nbsp;</div>
    <Container className="pt-3 text-light">
      <Row>
        <Col md={3}>
          <h2>{t('Management')}</h2>
          <hr />
          <Nav variant="pills" className="flex-column">
            <LinkContainer to="/admin/raffles">
              <Nav.Link href="/admin/raffles" active={false}>
                <i className="fa-solid fa-piggy-bank me-3"></i>{t('Raffles')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/group-buys">
              <Nav.Link href="/admin/group-buys" active={false}>
                <i className="fa-solid fa-people-group me-3"></i>{t('Group Buys')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/products">
              <Nav.Link active={false}>
                <i className="fa-solid fa-kitchen-set me-3"></i>{t('Products')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/mails">
              <Nav.Link active={false}>
                <i className="fa-solid fa-inbox me-3"></i>{t('Mails')}
              </Nav.Link>
            </LinkContainer>
            <Nav.Link active={false} disabled><i className="fa-solid fa-newspaper me-3"></i>Newsletters</Nav.Link>
            <Nav.Link active={false} disabled><i className="fa-solid fa-users me-3"></i>Users</Nav.Link>
          </Nav>
          <hr />
        </Col>
        <Col md={9}>
          <Outlet/>
        </Col>
      </Row>
    </Container>
  </>
}

export default AdminLayout;
