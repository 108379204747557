import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "../../../resolveJs";
import { get, getDatabase, ref, set } from 'firebase/database';
import { useNavigate, useParams } from 'react-router-dom';
import SubmitButton from "../../../components/SubmitButton";
import { toast } from "react-toastify";

function Edit() {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required().label('Subject'),
    to: Yup.string().required().label('To'),
    body: Yup.string().required().label('Body'),
  })

  const [formData, setFormData] = useState({
    subject: '',
    to: '',
    body: '',
  })
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const db = getDatabase()
      const dbRef = ref(db, `mails/${id}`)
      const dataSnapshot = await get(dbRef)
      if (!isSubscribed) {
        return
      }
      if (!dataSnapshot.size) {
        return navigate('/not-found')
      }
      const data = dataSnapshot.val()
      setFormData(data)
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [id, navigate])

  const handleSetFormData = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    setErrors({})

    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err:any) {
      const errors:any = {}
      for (const error of err.inner) {
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }

    try {
      const data:any = { ...formData }
      const db = getDatabase()
      const dbRef = ref(db, `mails/${id}`)
      await set(dbRef, data)
      toast(t('Update successful'), { type: 'success' })
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h2>{t('Edit Mail')}</h2>
        <div>
          <LinkContainer to="/admin/mails"><Button>{t('Back to List')}</Button></LinkContainer>
        </div>
      </div>
      <Form>
        <Form.Group className="mb-3" controlId="subject">
          <Form.Label>{t('Subject')}</Form.Label>
          <Form.Control type="text" name="subject" value={formData.subject} onChange={handleSetFormData} placeholder={t('Subject')} />
          {errors?.['subject']?.message && (<Form.Text className="text-danger">{t(errors?.['subject']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="to">
          <Form.Label>{t('To')}</Form.Label>
          <Form.Control type="text" name="to" value={formData.to} onChange={handleSetFormData} placeholder={t('To')} />
          {errors?.['to']?.message && (<Form.Text className="text-danger">{t(errors?.['to']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="body">
          <Form.Label>{t('body')}</Form.Label>
          <Form.Control as="textarea" rows={10} name="body" value={formData.body} onChange={handleSetFormData} />
          {errors?.['body']?.message && (<Form.Text className="text-danger">{t(errors?.['body']?.message)}</Form.Text>)}
        </Form.Group>
        {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
        <div className="d-grid gap-2 mt-5">
          <SubmitButton variant="primary" size="lg" type="submit" onClick={handleSubmit}>
            {t('Update')}
          </SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default Edit
