import { getAuth } from "firebase/auth"
import { get, getDatabase, onValue, query, ref } from "firebase/database"
import moment from "moment"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { adminUids } from "../configs"
import CloudinaryImage from "./CloudinaryImage"

function RaffleListOverview() {
  const [items, setItems] = useState<any>([])
  const [isTester, setIsTester] = useState<any>(false)

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const auth = getAuth()
      setIsTester(auth.currentUser && adminUids.indexOf(auth.currentUser.uid) >= 0)
      const db = getDatabase()
      const dbQuery = query(ref(db, 'raffleStatuses/start'))
      onValue(dbQuery, async (snapshot) => {
        if (!isSubscribed || !snapshot.exists()) {
          return
        }
        const raffles = Object.values(snapshot.val() || []).map((raffle: any) => ({
          ...raffle,
          ts: moment(raffle.time).unix(),
        })).sort((a: any, b: any) => b.ts - a.ts)
        const data: any = []
        let rfObj: any
        for (rfObj of raffles) {
          const raffleSnapshot = await get(query(ref(db, `raffles/${rfObj.id}`)))
          if (isSubscribed && raffleSnapshot.exists()) {
            data.push({
              ...raffleSnapshot.val(),
              id: rfObj.id,
            })
          }
        }
        setItems(data)
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [])

  if (!items.length || items.length <= 1) {
    return null
  }

  return <div className="d-block d-sm-none">
    <div className="mt-1">&nbsp;</div>
    <Container className="bg-warning pt-1">
      <h2 className="text-center">Running Raffles</h2>
      <Row>
        {!!items.length && items.map((raffle:any, index:number) => (!raffle.test || isTester) && raffle?.images?.length && (
          <Col className="mb-3" key={index}>
            <div className="rounded">
              <Link className="text-secondary" to={'/raffles/' + (raffle.alias || '')}>
                <CloudinaryImage className="w-100" image={raffle.images[0]} width={200} height={200} />
              </Link>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  </div>
}

export default RaffleListOverview;
