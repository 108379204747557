import { useEffect, useState } from "react"
import { get, getDatabase, onValue, ref } from 'firebase/database'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Container, Row, Table } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import CloudinaryImage from "../components/CloudinaryImage"
import { getAuth } from "firebase/auth"
import ShippingAddressForm from "../components/ShippingAddressForm"

function RafflePayment() {
  const { t } = useTranslation()
  const { alias, orderId } = useParams()
  const [ raffle, setRaffle ] = useState<any>(null)
  const [ order, setOrder ] = useState<any>(null)
  const [ user, setUser ] = useState<any>(null)
  const navigate = useNavigate()

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const auth = getAuth()
      setUser(auth.currentUser)
      const id: any = (await get(ref(getDatabase(), `raffleAliases/${alias}`))).val()
      if (!isSubscribed) {
        return
      }
      if (!id) {
        return navigate('/not-found')
      }
      const raffleDetails = (await get(ref(getDatabase(), `raffles/${id}`))).val()
      if (!isSubscribed) {
        return
      }
      if (!raffleDetails) {
        return navigate('/not-found')
      }
      setRaffle({
        id,
        ...raffleDetails
      })

      onValue(ref(getDatabase(), `raffleRegisters/${id}/users/${orderId}`), async (snapshot) => {
        if (!isSubscribed || !snapshot.exists()) {
          return navigate('/not-found')
        }
        const orderDetails = snapshot.val()
        if (orderDetails && orderDetails.winNotPaid) {
          return navigate('/payment/expiration')
        }
        if (!orderDetails || !(orderDetails.win || orderDetails.winByReroll)) {
          return navigate('/not-found')
        }
        if (!orderDetails.paid && orderDetails.country !== 'vn') {
          localStorage.setItem('pinfo', JSON.stringify({ id, orderId }))
        }
        setOrder(orderDetails)
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [alias, orderId, navigate])

  if (!raffle || !order) {
    return null
  }

  return <>
    <div className="my-5">&nbsp;</div>
    <Container className="text-light">
      <Row className="d-flex justify-content-center">
        <Col xl="8">
          <h2>{t('Invoice Details')}</h2>
          <Table variant="dark" striped bordered hover>
            <thead>
              <tr>
                <th style={{width: '30%'}}>{t('Product')}</th>
                <th className="text-end">{t('Quantity')}</th>
                <th className="text-end">{t('Price')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {raffle.images?.length && <span className="me-2">
                    <CloudinaryImage className="rounded" image={raffle.images[0]} width={100} height={100} />
                  </span>}
                  {raffle.name}
                </td>
                <td className="text-end">1</td>
                <td className="text-end">{raffle.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$</td>
              </tr>
              <tr>
                <th className="text-end" colSpan={2}>{t('Shipping')}</th>
                <td className="text-end">{order.shippingFee.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$</td>
              </tr>
              {order.country === 'vn' ? <tr>
                <th className="text-end" colSpan={2}>{t('Total')}</th>
                <td className="text-end">{order.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$</td>
              </tr> : <tr>
                <th className="text-end" colSpan={2}>{t('Total')}</th>
                <td className="text-end text-warning fw-bold">{order.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$</td>
              </tr>}
              {order.country === 'vn' && <>
              <tr>
                <th className="text-end" colSpan={2}>{t('Currency (USD-VND)')}</th>
                <td className="text-end">{order.currency.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}đ</td>
              </tr>
              <tr>
                <th className="text-end" colSpan={2}>{t('Total (VND)')}</th>
                <td className="text-end text-warning fw-bold">{order.totalVn.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}đ</td>
              </tr>
              </>}
            </tbody>
          </Table>
        </Col>
      </Row>
      {!order.paid && <Row className="d-flex justify-content-center mt-5">
        <Col xl="8">
          <h2>{t('Payment Methods')}</h2>
          <Table variant="dark" striped bordered hover>
            <tbody>
              <tr>
                <th>{t('Payment Content')}</th>
              </tr>
              <tr>
                <td className="p-3 text-warning fw-bold fs-3">{raffle.name} {order.code}</td>
              </tr>
              <tr>
                <th>{t('Payment Methods')}</th>
              </tr>
              {order.country === 'vn' && <>
              <tr>
                <td className="text-success">
                  <h5>Ngân hàng Techcombank</h5>
                  <ul>
                    <li>Name: VO THI YEN</li>
                    <li>Account: 19027455329023</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Note: Bạn vui lòng nhập nội dung thanh toán ở phần Payment Content khi chuyển khoản.<br/>
                  Sau khi chuyển khoản bạn vui lòng chờ hệ thống xử lý. Bạn sẽ nhận được email xác nhận thanh toán khi hệ thống kiểm tra thành công.<br/>
                  Nếu có thể vui lòng chụp hình hoá đơn chuyển khoản và gửi lại cho Enunicorn trong invoice email.
                </td>
              </tr>
              </>}
              {order.country === 'us' && <tr>
                <td>
                  <div><a className="btn btn-warning" href={raffle.paypalButtonEmail1} target="_blank" rel="noreferrer">Pay with PayPal</a></div>
                  <div>Please select quantity as 1 (one item) on paypal when making payment</div>
                  <div>Optional: After payment please take a picture of the transfer invoice and email it to Enunicorn in your invoice email. This helps us check out faster</div>
                </td>
              </tr>}
              {!(order.country === 'vn' || order.country === 'us') && <tr>
                <td>
                  <div><a className="btn btn-warning" href={raffle.paypalButtonEmail2} target="_blank" rel="noreferrer">Pay with PayPal</a></div>
                  <div>Please select quantity as 1 (one item) on paypal when making payment</div>
                  <div>Optional: After payment please take a picture of the transfer invoice and email it to Enunicorn in your invoice email. This helps us check out faster</div>
                </td>
              </tr>}
              <tr>
                <td className="text-danger">
                  Notice: Please only make transactions from the website https://enunicorn.com. Enunicorn is not responsible when you transact in other domains.
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>}
      {user && user.uid === orderId && <Row className="d-flex justify-content-center mt-5">
        <Col xl="8">
          <h2>{t('Shipping Information')}</h2>
          <ShippingAddressForm/>
        </Col>
      </Row>}
    </Container>
  </>
}

export default RafflePayment
