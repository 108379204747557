import { useTranslation } from 'react-i18next'
import SecurityForm from '../components/SecurityForm'

function Security() {
  const { t } = useTranslation()

  return (
    <>
      <div>
        <h2>{t('Change Password')}</h2>
        <SecurityForm/>
      </div>
    </>
  )
}

export default Security
