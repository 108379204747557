import { Col, Container, Nav, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { LinkContainer } from '../resolveJs'

function UserLayout() {
  const { t } = useTranslation()

  return <>
    <div className="my-5">&nbsp;</div>
    <Container className="pt-3 text-light">
      <Row>
        <Col md={3}>
          <h2>{t('User Settings')}</h2>
          <hr />
          <Nav variant="pills" className="flex-column">
            <LinkContainer to="/settings">
              <Nav.Link href="/settings" active={false}>
              <i className="fa-solid fa-address-card me-3"></i>{t('Profile')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/settings/shipping-addresses">
              <Nav.Link href="/settings/shipping-addresses" active={false}>
              <i className="fa-solid fa-truck me-3"></i>{t('Shipping Address')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/settings/security">
              <Nav.Link href="/settings/security" active={false}>
              <i className="fa-solid fa-user-shield me-3"></i>{t('Security')}
              </Nav.Link>
            </LinkContainer>
          </Nav>
          <hr />
        </Col>
        <Col md={9}>
          <Outlet/>
        </Col>
      </Row>
    </Container>
  </>
}

export default UserLayout
