import { Carousel, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { FlipCountdown } from '../resolveJs';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RegisterRaffle from './RegisterRaffle';
import CloudinaryImage from './CloudinaryImage';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { artisticFilter, grayscale } from '@cloudinary/url-gen/actions/effect';
import upcomingImg from '../assets/img/upcoming.png'
import FollowRaffle from './FollowRaffle';

interface RaffleProps {
  raffle:any,
  className?:string,
}

function Raffle(props:RaffleProps) {
  const { t } = useTranslation()
  const time = moment(props.raffle.time)
  const durationTime = moment(props.raffle.time).add(props.raffle.duration ?? 0, 'hours')
  const [status, setStatus] = useState<string>('upcoming')
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    let isSubscribed = true
    let interval: any = null
    const init = () => {
      if (!isSubscribed) {
        clearInterval(interval)
        return
      }
      const now = moment().toISOString()
      if (now < props.raffle.time) {
        setStatus('upcoming')
      } else if (now < moment(props.raffle.time).add(props.raffle.duration ?? 0, 'hours').toISOString()) {
        setStatus('running')
      } else if (now < moment(props.raffle.time).add(props.raffle.duration ?? 0, 'hours').add(1, 'day').toISOString()) {
        setStatus('ended')
        clearInterval(interval)
      } else {
        setStatus('closed')
        clearInterval(interval)
      }
    }
    init()
    interval = setInterval(init, 1000)
    setLoaded(true)
    return () => {
      isSubscribed = false
    }
  }, [props.raffle.duration, props.raffle.time])

  if (!loaded) {
    return <></>
  }

  if (status === 'upcoming') {
    return (
      <div className={"py-5 " + props.className}>
        <Container>
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <Carousel>
                {!!props.raffle.images?.length && props.raffle.images.map((image:any, index:number) => (
                  <Carousel.Item key={index}>
                    <div className="rounded overflow-hidden position-relative">
                      <CloudinaryImage className="w-100" image={image} width={800} height={800} effects={[grayscale(), artisticFilter('zorro')]} />
                      <img src={upcomingImg} alt="Upcoming" className="position-absolute start-50 bottom-50 w-25" />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
            <Col lg={6} className="d-flex align-items-center  my-3">
              <div className="text-center w-100">
                <h2>{t('Upcoming Raffle')}</h2>
                <p className="text-secondary">Raffle will start at {time.format('hh:mm A')} on {time.format('MMMM DD, YYYY')}</p>
                <div className="mt-4">
                  <FlipCountdown
                    hideYear
                    hideMonth
                    endAtZero
                    dayTitle="Days"
                    hourTitle="Hours"
                    minuteTitle="Minutes"
                    secondTitle="Seconds"
                    size="small"
                    endAt={time.format('YYYY-MM-DD HH:mm:ss')} // Date/Time
                  />
                </div>
                <div className="mt-4">
                  <FollowRaffle raffle={props.raffle} />
                </div>
                <p className="mt-3 text-secondary">{t('Follow the raffle now so you don\'t miss the product')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  const getProgressColor = (percent: number) => {
    if (percent <= 30) {
      return 'warning'
    } else if (percent <= 70) {
      return 'info'
    } else {
      return 'success'
    }
  }

  return (
    <div className={"py-5 " + props.className}>
      <Container>
        <Row>
          <Col lg={6} className="d-flex align-items-center">
            <Carousel>
              {!!props.raffle.images?.length && props.raffle.images.map((image:any, index:number) => (
                <Carousel.Item key={index}>
                  <div className="rounded overflow-hidden">
                    <CloudinaryImage className="w-100" image={image} width={800} height={800} />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col lg={6} className="d-flex align-items-center my-3">
            <div className="text-center w-100">
              <h2>{t(status === 'running' ? 'Running Raffle' : 'Closed Raffle')}{props.raffle.test ? ' [Testing]' : ''}</h2>
              <h3 className="text-secondary">Name: <Link className="text-secondary" to={'/raffles/' + (props.raffle.alias || '')}>{props.raffle.name}</Link></h3>
              {!!props.raffle.colorway && <h5 className="text-secondary">Colorway: {props.raffle.colorway}</h5>}
              <h6 className="text-secondary">
                Price: <span className="text-warning">${props.raffle.price}</span> - Quantity: <span className="text-warning">{props.raffle.quantity}</span> {props.raffle.quantity === 1 ? 'keycap' : 'keycaps'}
              </h6>
              {status === 'running' ?
                <p className="text-secondary">Raffle will close at {durationTime.format('hh:mm A')} on {durationTime.format('MMMM DD, YYYY')}</p> :
                <p className="text-secondary">Raffle closed at {durationTime.format('hh:mm A')} on {durationTime.format('MMMM DD, YYYY')}</p>
              }
              {status === 'running' && <div className="mt-4">
                <FlipCountdown
                  hideYear
                  hideMonth
                  endAtZero
                  dayTitle="Days"
                  hourTitle="Hours"
                  minuteTitle="Minutes"
                  secondTitle="Seconds"
                  size="small"
                  endAt={durationTime.format('YYYY-MM-DD HH:mm:ss')} // Date/Time
                />
              </div>}
              {status === 'running' ? <div className="mt-4">
                <RegisterRaffle raffle={props.raffle} />
              </div> : <div className="d-flex justify-content-center">
                <div>
                  <div>{t('Production Progress')}</div>
                  <div>{t('Estimated Shipping Date')}: {moment(props.raffle.time).add(props.raffle.duration ?? 0, 'hours').add(1, 'day').format('MMMM DD, YYYY')}</div>
                  <div className="d-flex justify-content-center">
                    <ProgressBar style={{width: 300}} animated variant={getProgressColor(props.raffle.progress)} now={props.raffle.progress || 0} label={`${props.raffle.progress || 0}%` + (props.raffle.progress === 100 ? t(' - Shipping') : '')} />
                  </div>
                </div>
              </div>}
              {status === 'running' && <p className="mt-3 text-secondary">{t('Join for the raffle now so you don\'t miss the product')}</p>}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Raffle;
