import { getDatabase, onValue, orderByChild, query, ref } from "firebase/database";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { raffleStatuses } from "../../../configs";
import { LinkContainer } from "../../../resolveJs";

function List() {
  const { t } = useTranslation()
  const [items, setItems] = useState<any>({})

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const db = getDatabase()
      const dbRef = ref(db, 'raffles')
      const dbQuery = query(dbRef, orderByChild('time'))
      onValue(dbQuery, (snapshot) => {
        if (!isSubscribed) {
          return
        }
        const data:any = []
        snapshot.forEach(child => { data.push({ id: child.key, ...child.val() }) })
        setItems(data.reverse())
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [])

  const getStatus = (item: any) => {
    const status = Object.values(raffleStatuses).find(status => status.value === item.status)
    if (status) {
      return status.name
    }
    return ''
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h2>{t('Raffles')}</h2>
        <div>
          <LinkContainer to="/admin/raffles/create"><Button>{t('Create')}</Button></LinkContainer>
        </div>
      </div>
      <Table variant="dark" striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Time')}</th>
            <th>{t('Quantity')}</th>
            <th>{t('Status')}</th>
          </tr>
        </thead>
        <tbody>
          {!items.length && (
            <tr>
              <td colSpan={4}>{t('Not Found')}</td>
            </tr>
          )}
          {!!items.length && items.map((item:any) => (
            <tr key={item.id}>
              <td><Link to={`/admin/raffles/show/${item.id}`}>{item.name} - {item.colorway}</Link></td>
              <td>{moment(item.time).format('YYYY-MM-DD HH:mm:ss')}</td>
              <td>{item.quantity}</td>
              <td>{getStatus(item)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default List
