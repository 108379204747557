import { getAuth } from 'firebase/auth';
import { get, getDatabase, ref, set } from 'firebase/database';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthModal from './AuthModal';
import ProfileModal from './ProfileModal';
import SubmitButton from './SubmitButton';

interface RegisterRaffleProps {
  raffle:any,
}

function RegisterRaffle(props:RegisterRaffleProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<any>({})
  const [registered, setRegistered] = useState<boolean>(false)
  const [authModalShow, setAuthModalShow] = useState<boolean>(false)
  const [profileModalShow, setProfileModalShow] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('closed')

  useEffect(() => {
    let isSubscribed = true
    const interval = setInterval(() => {
      if (!isSubscribed) {
        clearInterval(interval)
        return
      }
      const now = moment().toISOString()
      if (now < props.raffle.time) {
        setStatus('upcoming')
      } else if (now < moment(props.raffle.time).add(props.raffle.duration ?? 0, 'hours').toISOString()) {
        setStatus('running')
      } else if (now < moment(props.raffle.time).add(props.raffle.duration ?? 0, 'hours').add(1, 'day').toISOString()) {
        setStatus('ended')
        clearInterval(interval)
      } else {
        setStatus('closed')
        clearInterval(interval)
      }
    }, 1000)
    return () => {
      isSubscribed = false
    }
  }, [props.raffle.duration, props.raffle.time])

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user || status !== 'running') {
        return
      }
      const db = getDatabase()
      const dbRef = ref(db, `raffleRegisters/${props.raffle.id}/users/${user.uid}`)
      const dataSnapshot = await get(dbRef)
      if (!isSubscribed) {
        return
      }
      setRegistered(!!dataSnapshot.val())
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [status, props.raffle.id])

  const handleAfterLogin = async () => {
    setAuthModalShow(false)
    window.location.reload()
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault()

    const auth = getAuth()
    const user = auth.currentUser
    if (!user) {
      return setAuthModalShow(true)
    }

    const profile = (await get(ref(getDatabase(), `users/${user.uid}`))).val()
    if (!profile || !profile.phone || !profile.address || !profile.country) {
      return setProfileModalShow(true)
    }

    confirmAlert({
      closeOnClickOutside: false,
      title: t('Confirm to join'),
      message: t('Are you sure to join this raffle?'),
      buttons: [
        {
          label: t('Yes'),
          onClick: () => {
            handleRegister()
          }
        },
        {
          label: t('No'),
          onClick: () => {}
        }
      ]
    })
  }

  const handleRegister = async () => {
    setErrors({})

    if (status !== 'running') {
      return
    }

    try {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) {
        return navigate('/login', { state: { registerRaffleId: props.raffle.id } })
      }

      const db = getDatabase()
      await set(ref(db, `raffleRegisters/${props.raffle.id}/users/${user?.uid}/uid`), user?.uid)
      await set(ref(db, `raffleRegisters/${props.raffle.id}/users/${user?.uid}/displayName`), user?.displayName)
      await set(ref(db, `raffleRegisters/${props.raffle.id}/users/${user?.uid}/createdAt`), moment().toISOString())
      toast(t('Successfully joined for the raffle'), { type: 'success' })
      setRegistered(true)
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  return (
    <>
      <SubmitButton variant={status === 'running' ? (registered ? 'success' : 'warning') : 'danger'} size="lg" disabled={status !== 'running' || registered} onClick={handleSubmit}>
        {status === 'running' ? t(registered ? 'Already joined' : 'Join Now') : t('Raffle Closed')}
      </SubmitButton>
      {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
      <AuthModal show={authModalShow} onHide={handleAfterLogin}/>
      <ProfileModal show={profileModalShow} onHide={() => setProfileModalShow(false)}/>
    </>
  );
}

export default RegisterRaffle;
