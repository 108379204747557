import * as Yup from 'yup'
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAuth, updatePassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import SubmitButton from "./SubmitButton";

function SecurityForm() {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(6).required().label('Password'),
    confirmPassword: Yup.string().min(6).oneOf([Yup.ref('password'), null], 'Confirm Password and Password must match').required().label('Confirm Password'),
  })

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState<any>({})

  const handleSetFormData = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    setErrors({})

    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err:any) {
      const errors:any = {}
      for (const error of err.inner) {
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }

    try {
      const data:any = { ...formData }
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) {
        throw new Error('Require login')
      }
      await updatePassword(user, data.password)
      toast(t('Update password successful'), { type: 'success' })
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formRegisterPassword">
        <Form.Label>{t('Password')}</Form.Label>
        <Form.Control type="password" name="password" value={formData.password} onChange={handleSetFormData} placeholder={t('Password')} />
        {errors?.['password']?.message && (<Form.Text className="text-danger">{t(errors?.['password']?.message)}</Form.Text>)}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formRegisterConfirmPassword">
        <Form.Label>{t('Confirm Password')}</Form.Label>
        <Form.Control type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleSetFormData} placeholder={t('Password')} />
        {errors?.['confirmPassword']?.message && (<Form.Text className="text-danger">{t(errors?.['confirmPassword']?.message)}</Form.Text>)}
      </Form.Group>
      {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
      <div className="d-grid gap-2 mt-5">
        <div className="btn-group">
          <SubmitButton variant="primary" size="lg" type="submit" onClick={handleSubmit}>
            {t('Update')}
          </SubmitButton>
        </div>
      </div>
    </Form>
  );
}

export default SecurityForm;
