import { useEffect, useState } from "react"
import { get, getDatabase, ref } from 'firebase/database'
import { useNavigate, useParams } from 'react-router-dom'
import Raffle from '../components/Raffle'
import RaffleListOverview from "../components/RaffleListOverview"

function RaffleDetails() {
  const { alias } = useParams()
  const [item, setItem] = useState<any>(null)
  const navigate = useNavigate()

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const id: any = (await get(ref(getDatabase(), `raffleAliases/${alias}`))).val()
      if (!isSubscribed) {
        return
      }
      if (!id) {
        return navigate('/not-found')
      }
      const raffle = (await get(ref(getDatabase(), `raffles/${id}`))).val()
      if (!isSubscribed) {
        return
      }
      if (!raffle) {
        return navigate('/not-found')
      }
      setItem({
        id,
        ...raffle
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [alias, navigate])

  return !!item ? (
    <>
      <div className="my-4">&nbsp;</div>
      <RaffleListOverview/>
      <Raffle raffle={item} className="bg-theme text-light" />
    </>
  ) : <></>
}

export default RaffleDetails
