import moment from "moment";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { get, getDatabase, orderByChild, query, ref, set } from 'firebase/database';
import { confirmAlert } from "react-confirm-alert";
import { countries } from 'country-code-lookup'

interface RaffleRegistersProps {
  id?:string,
}

function RaffleRegisters(props:RaffleRegistersProps) {
  const { t } = useTranslation()
  const { id } = props
  const [reload, setReload] = useState<number>(moment().unix())
  const [items, setItems] = useState<any>({})

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const db = getDatabase()
      const dbRef = ref(db, `raffleRegisters/${id}/users`)
      const dbQuery = query(dbRef, orderByChild('createdAt'))
      const dataSnapshot = await get(dbQuery)
      if (!isSubscribed) {
        return
      }
      const data:any = []
      dataSnapshot.forEach(child => { data.push({ id: child.key, ...child.val() }) })
      setItems(data)
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [id, reload])

  const handleShip = async (item: any) => {
    const snapshot = await get(ref(getDatabase(), `users/${item.uid}`))
    if (!snapshot.exists()) {
      return
    }
    const user = snapshot.val()
    const country = countries.find(ct => ct.internet === user?.shippingCountry?.toUpperCase() || ct.internet === user?.country?.toUpperCase())
    let trackingNumber = ''
    let trackingSite = ''
    if (user?.country === 'vn') {
      trackingSite = 'https://viettelpost.com.vn'
    } else {
      trackingSite = 'https://t.17track.net or http://track.quantiumsolutions.com'
    }
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h2>{t('Shipping address')}</h2>
            <ul>
              <li>{t('Name')}: {user.shippingName ?? user.displayName}</li>
              <li>{t('Phone')}: {user.shippingPhone ?? user.phone}</li>
              <li>{t('Address Line 1')}: {user.shippingAddressLine1 ?? user.address}</li>
              <li>{t('Address Line 2')}: {user.shippingAddressLine2}</li>
              <li>{t('City')}: {user.shippingCity}</li>
              <li>{t('State')}: {user.shippingState}</li>
              <li>{t('Country')}: {country?.country ?? user?.country?.toUpperCase()} - {user?.country?.toUpperCase()}</li>
              <li>{t('Postal Code')}: {user.shippingPostalCode}</li>
            </ul>
            <p>{t('Click Yes when you have shipped the item')}</p>
            <Form>
              <Form.Group className="mb-3" controlId="trackingShipment">
                <Form.Label>{t('Tracking Shipment')}</Form.Label>
                <Form.Control className="mb-1" type="text" onChange={(e: any) => trackingNumber = e.target.value} placeholder={t('Tracking Number')} />
                <Form.Control type="text" defaultValue={trackingSite} onChange={(e: any) => trackingSite = e.target.value} placeholder={t('Tracking Site')} />
              </Form.Group>
            </Form>
            <div className="react-confirm-alert-button-group">
              <button
                onClick={async () => {
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/shipped`), true)
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/shippingName`), user.shippingName ?? user.displayName ?? '')
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/shippingPhone`), user.shippingPhone ?? user.phone ?? '')
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/shippingAddressLine1`), user.shippingAddressLine1 ?? user.address ?? '')
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/shippingAddressLine2`), user.shippingAddressLine2 ?? '')
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/shippingCity`), user.shippingCity ?? '')
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/shippingState`), user.shippingState ?? '')
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/shippingCountry`), country?.country ?? user?.country?.toUpperCase() ?? '')
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/shippingPostalCode`), user.shippingPostalCode ?? '')
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/trackingShipment`), { trackingNumber, trackingSite })
                  await set(ref(getDatabase(), `raffles/${id}/sentShipmentConfirmationMails`), false)
                  setReload(moment().unix())
                  onClose()
                }}
              >
                {t('Yes')}
              </button>
              <button onClick={onClose}>{t('No')}</button>
            </div>
          </div>
        );
      }
    })
  }

  const handleContactProxy = async (item: any) => {
    let carrierInfo = 'Phone: '
    let trackingNumber = item?.trackingShipment?.trackingNumber ?? ''
    let trackingSite = item?.trackingShipment?.trackingSite ?? ''
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h2>{t('Contact proxy')}</h2>
            <Form>
              <Form.Group className="mb-3" controlId="trackingShipment">
                <Form.Label>{t('Carrier Information')}</Form.Label>
                <Form.Control type="text" defaultValue={carrierInfo} onChange={(e: any) => carrierInfo = e.target.value} placeholder={t('Carrier Information')} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="trackingShipment">
                <Form.Label>{t('Tracking Shipment')}</Form.Label>
                <Form.Control className="mb-1" type="text" defaultValue={trackingNumber} onChange={(e: any) => trackingNumber = e.target.value} placeholder={t('Tracking Number')} />
                <Form.Control type="text" defaultValue={trackingSite} onChange={(e: any) => trackingSite = e.target.value} placeholder={t('Tracking Site')} />
              </Form.Group>
            </Form>
            <div className="react-confirm-alert-button-group">
              <button
                onClick={async () => {
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/sentContactProxyMail`), false)
                  await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/trackingShipment`), { trackingNumber, trackingSite, carrierInfo })
                  await set(ref(getDatabase(), `raffles/${id}/sentContactProxyMails`), false)
                  setReload(moment().unix())
                  onClose()
                }}
              >
                {t('Yes')}
              </button>
              <button onClick={onClose}>{t('No')}</button>
            </div>
          </div>
        );
      }
    })
  }

  const handlePaid = async (item: any) => {
    confirmAlert({
      closeOnClickOutside: false,
      title: t('Confirm to submit'),
      message: t(`Are you sure this person paid ${money(item)}?`),
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/paid`), true)
            await set(ref(getDatabase(), `raffles/${id}/sentPaymentConfirmationMails`), false)
            setReload(moment().unix())
          }
        },
        {
          label: t('No'),
          onClick: () => {}
        }
      ]
    })
  }
  const handleShowInfo = async (item: any) => {
    const snapshot = await get(ref(getDatabase(), `users/${item.uid}`))
    if (!snapshot.exists()) {
      return
    }
    const user = snapshot.val()
    const country = countries.find(ct => ct.internet === user?.shippingCountry?.toUpperCase() || ct.internet === user?.country?.toUpperCase())
    const handleMark = async (mark: boolean, onClose: any) => {
      item.marked = mark
      await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/marked`), !!mark)
      onClose()
    }
    const handleBan = async (ban: boolean, onClose: any) => {
      item.ban = ban
      await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/ban`), !!ban)
      onClose()
    }
    const handleConfirmAddress = async (confirmAddress: boolean, onClose: any) => {
      item.confirmAddress = confirmAddress
      await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/confirmAddress`), !!confirmAddress)
      if (!!confirmAddress) {
        await set(ref(getDatabase(), `raffleRegisters/${id}/users/${item.id}/sentConfirmAddressMail`), false)
        await set(ref(getDatabase(), `raffles/${id}/sentConfirmAddressMails`), false)
      }
      onClose()
    }
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h2>{t('Shipping address')}</h2>
            <ul>
              <li>{t('Id')}: {item.uid}</li>
              <li>{t('Email')}: {user.email}</li>
              <li>{t('Name')}: {user.shippingName ?? user.displayName}</li>
              <li>{t('Phone')}: {user.shippingPhone ?? user.phone}</li>
              <li>{t('Address Line 1')}: {user.shippingAddressLine1 ?? user.address}</li>
              <li>{t('Address Line 2')}: {user.shippingAddressLine2}</li>
              <li>{t('City')}: {user.shippingCity}</li>
              <li>{t('State')}: {user.shippingState}</li>
              <li>{t('Country')}: {country?.country ?? user?.country?.toUpperCase()}</li>
              <li>{t('Postal Code')}: {user.shippingPostalCode}</li>
            </ul>
            <div className="react-confirm-alert-button-group">
              <Button onClick={() => handleConfirmAddress(!item.confirmAddress, onClose)} variant="success">{t(item.confirmAddress ? 'Cancel Mail Confirm Address' : 'Mail Confirm Address')}</Button>
            </div>
            <div className="react-confirm-alert-button-group">
              <Button onClick={() => handleMark(!item.marked, onClose)} variant="success">{t(item.marked ? 'Un-mark' : 'Mark')}</Button>
              <Button onClick={() => handleBan(!item.ban, onClose)} variant="success">{t(item.ban ? 'Un-ban' : 'Ban')}</Button>
              {isPendingPaid(item) && <Button variant="success" onClick={() => {onClose(); handlePaid(item)}}>{t('Paid')}</Button>}
              <Button onClick={onClose}>{t('Close')}</Button>
            </div>
          </div>
        );
      }
    })
  }

  const status = (item: any) => {
    if (item.shipped) {
      return 'Shipped'
    }
    if (item.paid) {
      return 'Paid'
    }
    if (item.winNotPaid) {
      return 'Not Paid'
    }
    if (item.winByReroll) {
      return 'Win by reroll'
    }
    if (item.win) {
      return 'Win'
    }
    return ''
  }

  const isPendingPaid = (item: any) => {
    return !!item.win && !item.paid && !item.winNotPaid
  }

  const money = (item: any) => {
    return item?.country === 'vn' ?
      `${item?.totalVn.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}đ` :
      `${item.total?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$`
  }

  return (
    <>
      <Table variant="dark" striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t('#')}</th>
            <th>{t('Name')}</th>
            <th className="text-center">{t('Status')}</th>
            <th className="text-center">{t('Code')}</th>
            <th className="text-end">{t('Total')}</th>
            <th className="text-end">{t('Action')}</th>
          </tr>
        </thead>
        <tbody>
          {!items.length && (
            <tr>
              <td colSpan={6}>{t('Not Found')}</td>
            </tr>
          )}
          {!!items.length && items.map((item:any, index: number) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.displayName}</td>
              <td className="text-center">{t(status(item))}</td>
              <td className="text-center">{item.code}</td>
              <td className="text-end">{isPendingPaid(item) || item.paid ? money(item) : ''}</td>
              <td className="text-end">
                <ButtonGroup aria-label="Actions">
                  <Button size="sm" onClick={() => handleShowInfo(item)}>{t('Info')}</Button>
                  {item.paid && !item.shipped && <Button size="sm" variant="success" onClick={() => handleShip(item)}>{t('Ship')}</Button>}
                  {isPendingPaid(item) && <Button size="sm" onClick={() => handlePaid(item)}>{t('Paid')}</Button>}
                  {item.paid && item.shipped && <Button size="sm" variant="success" onClick={() => handleContactProxy(item)}>{t('Contact')}</Button>}
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default RaffleRegisters
