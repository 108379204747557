import { get, getDatabase, ref } from 'firebase/database'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Carousel, Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CloudinaryImage from '../components/CloudinaryImage'

function Authenticity() {
  const { code } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [ formData, setFormData ] = useState({
    code: code ?? ''
  })
  const [ product, setProduct ] = useState<any>(null)

  useEffect(() => {
    if (!code) {
      return
    }
    let isSubscribed = true
    const fetchData = async () => {
      const productId: any = (await get(ref(getDatabase(), `keycapCodes/${code}`))).val()
      if (!isSubscribed) {
        return
      }
      if (!productId) {
        return setProduct(null)
      }
      if (productId.indexOf('gb|') === 0) {
        const groupBuy = (await get(ref(getDatabase(), `groupBuys/${productId.slice(3)}`))).val()
        if (!isSubscribed) {
          return
        }
        if (!groupBuy) {
          return setProduct(null)
        }
        setProduct({
          ...groupBuy,
          type: 'groupBuy',
        })
      } else {
        const raffle = (await get(ref(getDatabase(), `raffles/${productId}`))).val()
        if (!isSubscribed) {
          return
        }
        if (!raffle) {
          return setProduct(null)
        }
        setProduct({
          ...raffle,
          type: 'raffle',
        })
      }
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [ code ])

  const handleSetFormData = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (formData.code) {
      return navigate(`/authenticity/${formData.code.toUpperCase()}`)
    }
    return false
  }

  return <>
    <div className="my-5">&nbsp;</div>
    <Container className="text-light my-5 py-5">
      <Row className="justify-content-md-center text-center">
        <Col md={6} lg={5} xl={4}>
          <h2 className="text-uppercase mb-4">{t('Authenticity Check')}</h2>
          <p>{t('Please enter the code of product you wish to authenticate and we will do the magic.')}</p>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-4" controlId="formCodeInput">
              <Form.Control type="text" name="code" value={formData.code} onChange={handleSetFormData} placeholder={t('Code')} />
            </Form.Group>
            {!!formData.code ? <Link to={`/authenticity/${formData.code.toUpperCase()}`} className="btn btn-primary">
              <i className="me-2 fa-solid fa-search"></i>{t('Check')}
            </Link> : <Button variant="light" disabled>
              <i className="me-2 fa-solid fa-search"></i>{t('Check')}
            </Button>}
          </Form>
        </Col>
      </Row>
      {!!code && <Row className="justify-content-md-center text-center mt-5 pt-5">
        <Col md={8} lg={7} xl={6}>
          {!product ? <div>{t('No results matching your search!')}</div> : <div>
            <Carousel>
              {!!product.images?.length && product.images.map((image:any, index:number) => (
                <Carousel.Item key={index}>
                  <div className="rounded position-relative">
                   <CloudinaryImage className="w-100 rounded" image={image} width={800} height={600} />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
            {product.type === 'raffle' ? <div className="mt-5 text-primary">
              {!!product.name && <p><span className="text-muted">Name:</span> {product.name}</p>}
              {!!product.colorway && <p><span className="text-muted">Colorway:</span> {product.colorway}</p>}
              <p><span className="text-muted">Production method:</span> Manual multi-layer resin casting</p>
              {!!product.price && <p><span className="text-muted">Price:</span> ${product.price}</p>}
              {!!product.quantity && <p><span className="text-muted">Quantity:</span> limited to {product.quantity} keycaps</p>}
              {!!product.codes[code]?.order && <p><span className="text-muted">Product Order:</span> #{product.codes[code]?.order}</p>}
              {!!code && <p><span className="text-muted">Product Code:</span> {code}</p>}
              {!!product.time && <p><span className="text-muted">Raffle Time:</span> {moment(product.time).format('YYYY-MM-DD HH:mm:ss')}</p>}
            </div> : <div className="mt-5 text-primary">
              {!!product.name && <p><span className="text-muted">Name:</span> {product.name}</p>}
              {!!product.colorway && <p><span className="text-muted">Colorway:</span> {product.colorway}</p>}
              <p><span className="text-muted">Production method:</span> Manual multi-layer resin casting</p>
              {!!product.price && <p><span className="text-muted">Price:</span> ${product.price}</p>}
              {!!product.quantity && <p><span className="text-muted">Quantity:</span> limited to {product.quantity} keycaps</p>}
              {!!product.codes[code]?.order && <p><span className="text-muted">Product Order:</span> #{product.codes[code]?.order}</p>}
              {!!code && <p><span className="text-muted">Product Code:</span> {code}</p>}
              {!!product.time && <p><span className="text-muted">Group Buy Time:</span> {moment(product.time).format('YYYY-MM-DD HH:mm:ss')}</p>}
            </div>}
          </div>}
        </Col>
      </Row>}
    </Container>
  </>
}

export default Authenticity
