import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ParallaxProvider } from 'react-scroll-parallax';
import './assets/css/index.scss';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { FirebaseAuthProvider } from '@react-firebase/auth';
import { FirebaseDatabaseProvider } from "@react-firebase/database";
import firebaseConfig from './configs/firebase';
import ScrollToTop from './ScrollToTop';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {}
    }
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
})

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <FirebaseDatabaseProvider firebase={firebase} {...firebaseConfig}>
        <BrowserRouter>
          <ParallaxProvider>
            <ScrollToTop/>
            <App />
          </ParallaxProvider>
        </BrowserRouter>
      </FirebaseDatabaseProvider>
    </FirebaseAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
