import { Col, Container, Row } from "react-bootstrap";
import image1Img from '../assets/img/aboutbg1.jpg'
import image2Img from '../assets/img/aboutbg2.jpg'
import image3Img from '../assets/img/aboutbg3.jpg'
import RaffleList from "../components/RaffleList";

function Home() {
  return (
    <>
      <div className="my-5">&nbsp;</div>
      <RaffleList/>
      <div className="Home-Background-1 text-primary">
        <Container>
          <h1>Artisan Keycap</h1>
          <p>Put Art on your keyboard</p>
        </Container>
      </div>
      <Container className="my-5 py-5 text-center">
        <h3 className="text-primary">Philosophy</h3>
        <h1 className="pb-4 text-light">Handcrafted Artifacts</h1>
        <p className="text-muted">We aim to bring you a completely handmade product from prototyping to the finished product</p>
      </Container>
      <Container className="my-5">
        <Row>
          <Col xs="12" md="4" className="mb-5">
            <img className="w-100 rounded" src={image1Img} alt="" />
          </Col>
          <Col xs="12" md="4" className="mb-5">
            <img className="w-100 rounded" src={image2Img} alt="" />
          </Col>
          <Col xs="12" md="4" className="mb-5">
            <img className="w-100 rounded" src={image3Img} alt="" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
