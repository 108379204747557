import { getAuth } from "firebase/auth"
import { get, getDatabase, onValue, query, ref } from "firebase/database"
import moment from "moment"
import { useEffect, useState } from "react"
import { adminUids } from "../configs"
import Raffle from "./Raffle"

interface RaffleListProps {
  hideHello?: Boolean,
}

function RaffleList(props: RaffleListProps) {
  const [items, setItems] = useState<any>([])
  const [isTester, setIsTester] = useState<any>(false)

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const auth = getAuth()
      setIsTester(auth.currentUser && adminUids.indexOf(auth.currentUser.uid) >= 0)
      const db = getDatabase()
      const dbQuery = query(ref(db, 'raffleStatuses/start'))
      onValue(dbQuery, async (snapshot) => {
        if (!isSubscribed || !snapshot.exists()) {
          return
        }
        const raffles = Object.values(snapshot.val() || []).map((raffle: any) => ({
          ...raffle,
          ts: moment(raffle.time).unix(),
        })).sort((a: any, b: any) => b.ts - a.ts)
        const data: any = []
        let rfObj: any
        for (rfObj of raffles) {
          const raffleSnapshot = await get(query(ref(db, `raffles/${rfObj.id}`)))
          if (isSubscribed && raffleSnapshot.exists()) {
            data.push({
              ...raffleSnapshot.val(),
              id: rfObj.id,
            })
          }
        }
        setItems(data)
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [])

  return (
    <>
      {!props.hideHello && !items.filter((item: any) => !item.test).length && !isTester && <div className="my-5 py-5 text-center">
        <h1 className="py-5 text-primary">Hello World</h1>
      </div>}

      {!!items.length && items.map((item:any, index:number) => (!item.test || isTester) && (
        <Raffle key={item.id} raffle={item} className={index % 2 ? 'bg-dark text-light' : 'bg-theme text-light'} />
      ))}
    </>
  );
}

export default RaffleList;
