import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QRCodeCanvas } from 'qrcode.react';
import { get, getDatabase, ref, set } from 'firebase/database';

interface QRCodesProps {
  id?: string,
  groupBuy?: any,
}

function QRCodes(props:QRCodesProps) {
  const { t } = useTranslation()
  const { id, groupBuy } = props
  const [ codes, setCodes ] = useState<any>([])

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      if (!isSubscribed) {
        return
      }
      setCodes(Object.values(groupBuy?.codes ?? {}))
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [id, groupBuy])

  const generateCode = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleGenerateCodes = async () => {
    if (groupBuy.codesGenerated) {
      return
    }
    let count = 0
    while (count < groupBuy.quantity) {
      const code = generateCode(6)
      const exists = (await get(ref(getDatabase(), `keycapCodes/${code}`))).val()
      if (!exists) {
        count++
        await set(ref(getDatabase(), `keycapCodes/${code}`), `gb|${id}`)
        await set(ref(getDatabase(), `groupBuys/${id}/codes/${code}`), {
          code,
          order: count,
          printed: false,
        })
      }
    }
    await set(ref(getDatabase(), `groupBuys/${id}/codesGenerated`), true)
  }

  const handlePrint = async (item: any) => {
    await set(ref(getDatabase(), `groupBuys/${id}/codes/${item.code}/printed`), !item.printed)
  }

  return (
    <>
      {!groupBuy.codesGenerated && <div className="mb-3">
        <Button onClick={handleGenerateCodes}>Generate QR Codes</Button>
      </div>}
      <Table variant="dark" striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t('#')}</th>
            <th>{t('Code')}</th>
            <th className="text-center">{t('QR Code')}</th>
            <th className="text-end">{t('Action')}</th>
          </tr>
        </thead>
        <tbody>
          {!codes.length && (
            <tr>
              <td colSpan={4}>{t('Not Found')}</td>
            </tr>
          )}
          {!!codes.length && codes.sort((a: any, b: any) => a.order - b.order).map((item:any, index: number) => (
            <tr key={index}>
              <td>{item.order}</td>
              <td>{item.code}</td>
              <td className="text-center"><QRCodeCanvas value={'https://enunicorn.com/authenticity/' + item.code} /></td>
              <td className="text-end">
                <a className="btn btn-primary btn-sm me-1" href={'https://enunicorn.com/authenticity/' + item.code} target="_blank" rel="noreferrer">Authenticity</a>
                {<Button size="sm" variant={item.printed ? 'success' : 'primary'} onClick={() => handlePrint(item)}>{t(item.printed ? 'Printed' : 'Mark Printed')}</Button>}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default QRCodes
