import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "../resolveJs";

function NotFound() {
  const { t } = useTranslation()

  return (
    <Container className="my-5 py-5 d-flex justify-content-center align-items-center">
      <div className="my-5 py-5 text-center text-light">
        <h1>{t('Oops!')}</h1>
        <h2>{t('404 - Page not found')}</h2>
        <p>{t('The page you are looking for might have been removed had its name changed or is temporarily unavailable')}</p>
        <p>
          <LinkContainer to="/">
            <Button>{t('Go to Homepage')}</Button>
          </LinkContainer>
        </p>
      </div>
    </Container>
  );
}

export default NotFound;
