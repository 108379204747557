import { getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CloudinaryImage from "../../../components/CloudinaryImage";
import { LinkContainer } from "../../../resolveJs";

function List() {
  const { t } = useTranslation()
  const [items, setItems] = useState<any>({})

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const db = getDatabase()
      const dbRef = ref(db, 'products')
      onValue(dbRef, (snapshot) => {
        if (!isSubscribed) {
          return
        }
        const data:any = []
        snapshot.forEach(child => { data.push({ id: child.key, ...child.val() }) })
        setItems(data.reverse())
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h2>{t('Products')}</h2>
        <div>
          <LinkContainer to="/admin/products/create"><Button>{t('Create')}</Button></LinkContainer>
        </div>
      </div>
      <Table variant="dark" striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{width: 110}}>{t('Image')}</th>
            <th>{t('Name')}</th>
          </tr>
        </thead>
        <tbody>
          {!items.length && (
            <tr>
              <td colSpan={2}>{t('Not Found')}</td>
            </tr>
          )}
          {!!items.length && items.map((item:any) => (
            <tr key={item.id}>
              <td>{!!item.image && <CloudinaryImage image={item.image} width={100} height={100} />}</td>
              <td><Link to={`/admin/products/show/${item.id}`}>{item.name}</Link></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default List
