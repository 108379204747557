export const apiUrl = process.env.REACT_APP_API_URL

export const adminUids = (process.env.REACT_APP_ADMIN_UIDS || '').split(',')

export const raffleStatuses = {
  upcoming: { name: 'Upcoming', value: -1 },
  new: { name: 'Running', value: 0 },
  raffle: { name: 'Raffle', value: 1 },
  reroll: { name: 'Reroll', value: 2 },
  paid: { name: 'Paid', value: 3 },
  shipping: { name: 'Shipping', value: 4 },
  finished: { name: 'Finished', value: 10 },
}
