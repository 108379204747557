import moment from "moment";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DateTimePicker, LinkContainer } from "../../../resolveJs";
import { get, getDatabase, onValue, ref, remove, set } from 'firebase/database';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import { raffleStatuses } from "../../../configs";
import RaffleRegisters from "./RaffleRegisters";
import CloudinaryImage from "../../../components/CloudinaryImage";
import buyNowImg from '../../../assets/img/buynow.gif'
import QRCodes from "./QRCodes";

function Show() {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [item, setItem] = useState<any>({})
  const [followers, setFollowers] = useState<number>(0)

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const db = getDatabase()
      const dbRef = ref(db, `raffles/${id}`)
      onValue(dbRef, (dataSnapshot) => {
        if (!isSubscribed) {
          return
        }
        if (!dataSnapshot.size) {
          return navigate('/not-found')
        }
        setItem({
          id,
          ...dataSnapshot.val()
        })
      })
      onValue(ref(db, `raffleFollowers/${id}/users`), (dataSnapshot) => {
        if (!isSubscribed || !dataSnapshot.size) {
          return
        }
        setFollowers(dataSnapshot.size)
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [id, navigate])

  const handleDelete = (e:any) => {
    e.preventDefault()
    confirmAlert({
      closeOnClickOutside: false,
      title: t('Confirm to submit'),
      message: t('Are you sure to do this?'),
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            const db = getDatabase()
            await remove(ref(db, `raffles/${id}`))
            await remove(ref(db, `raffleRegisters/${id}`))
            await remove(ref(db, `raffleFollowers/${id}`))
            await remove(ref(db, `raffleAliases/${item.alias}`))
            await remove(ref(db, `raffleStatuses/start/${id}`))
            await remove(ref(db, `raffleStatuses/running/${id}`))
            navigate('/admin/raffles')
          }
        },
        {
          label: t('No'),
          onClick: () => {}
        }
      ]
    })
  }

  const handleDelayDelivery = (e:any) => {
    e.preventDefault()
    let reason = ''
    let expectedDeliveryDate = ''
    let country = 'all'
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h2>{t('Delay Delivery')}</h2>
            <Form>
              <Form.Group className="mb-3" controlId="reason">
                <Form.Label>{t('Reason')}</Form.Label>
                <Form.Control className="mb-1" as="textarea" rows={3} onChange={(e: any) => reason = e.target.value} placeholder={t('Content')} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="expectedDeliveryDate">
                <Form.Label>{t('Expected Delivery Date')}</Form.Label>
                <DateTimePicker
                  dateFormat="YYYY-MM-DD"
                  renderInput={(props:any) => <Form.Control {...props} placeholder={t('Time')}/>}
                  onChange={(value: any) => expectedDeliveryDate = moment(value).format('MMMM DD, YYYY')}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="test">
                <Form.Label>{t('Country')}</Form.Label>
                <Form.Select defaultValue={country} onChange={(e: any) => country = e.target.value}>
                  <option value="vn">Viet Nam</option>
                  <option value="other">Other</option>
                  <option value="all">All</option>
                </Form.Select>
              </Form.Group>
            </Form>
            <div className="react-confirm-alert-button-group">
              <button
                onClick={async () => {
                  const snapshot = await get(ref(getDatabase(), `raffleRegisters/${id}/users`))
                  if (snapshot.exists()) {
                    const uids = Object.values(snapshot.val()).filter((u: any) => u.paid && !u.shipped)
                    let user: any
                    for (user of uids) {
                      await remove(ref(getDatabase(), `raffleRegisters/${id}/users/${user.uid}/sentDelayDeliveryMail`))
                    }
                  }
                  await set(ref(getDatabase(), `raffles/${id}/delayDelivery`), {
                    reason,
                    expectedDeliveryDate,
                    country,
                  })
                  await set(ref(getDatabase(), `raffles/${id}/sentDelayDeliveryMails`), false)
                  onClose()
                }}
              >
                {t('Yes')}
              </button>
              <button onClick={onClose}>{t('No')}</button>
            </div>
          </div>
        );
      }
    })
  }

  const handleSetProgress = async (raffle: any, progress: number) => {
    await set(ref(getDatabase(), `raffles/${raffle.id}/progress`), progress)
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h2>{t('View Raffle')}</h2>
        <div>
          <LinkContainer to="/admin/raffles"><Button>{t('Back to List')}</Button></LinkContainer>
        </div>
      </div>
      <Tabs defaultActiveKey="info" className="mb-3">
        <Tab eventKey="info" title="Raffle Information">
          <Form>
            {item.status >= 1 && item.status < 10 && <Row>
              <Col>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>{t('Progress')}</Form.Label>
                  <div>
                    <ButtonGroup aria-label={t('Progress')}>
                      <Button size="sm" onClick={() => handleSetProgress(item, 10)} variant={item.progress === 10 ? 'danger' : 'warning'}>10</Button>
                      <Button size="sm" onClick={() => handleSetProgress(item, 20)} variant={item.progress === 20 ? 'danger' : 'warning'}>20</Button>
                      <Button size="sm" onClick={() => handleSetProgress(item, 30)} variant={item.progress === 30 ? 'danger' : 'warning'}>30</Button>
                      <Button size="sm" onClick={() => handleSetProgress(item, 40)} variant={item.progress === 40 ? 'danger' : 'info'}>40</Button>
                      <Button size="sm" onClick={() => handleSetProgress(item, 50)} variant={item.progress === 50 ? 'danger' : 'info'}>50</Button>
                      <Button size="sm" onClick={() => handleSetProgress(item, 60)} variant={item.progress === 60 ? 'danger' : 'info'}>60</Button>
                      <Button size="sm" onClick={() => handleSetProgress(item, 70)} variant={item.progress === 70 ? 'danger' : 'info'}>70</Button>
                      <Button size="sm" onClick={() => handleSetProgress(item, 80)} variant={item.progress === 80 ? 'danger' : 'success'}>80</Button>
                      <Button size="sm" onClick={() => handleSetProgress(item, 90)} variant={item.progress === 90 ? 'danger' : 'success'}>90</Button>
                      <Button size="sm" onClick={() => handleSetProgress(item, 100)} variant={item.progress === 100 ? 'danger' : 'success'}>100</Button>
                    </ButtonGroup>
                  </div>
                </Form.Group>
              </Col>
            </Row>}
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="id">
                  <Form.Label>{t('Id')}</Form.Label>
                  <div><Form.Text>{item.id}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>{t('Name')}</Form.Label>
                  <div><Form.Text>{item.name}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="alias">
                  <Form.Label>{t('Alias')}</Form.Label>
                  <div><Form.Text>{item.alias}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="colorway">
                  <Form.Label>{t('Colorway')}</Form.Label>
                  <div><Form.Text>{item.colorway}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>{t('Description')}</Form.Label>
                  <div><Form.Text>{item.description}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="time">
                  <Form.Label>{t('Upcoming Time')}</Form.Label>
                  <div><Form.Text>{moment(item.time).format('YYYY-MM-DD HH:mm:ss')}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="time">
                  <Form.Label>{t('Join Duration')}</Form.Label>
                  <div><Form.Text>{item.duration} hours</Form.Text></div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>{t('Followers')}</Form.Label>
                  <div><Form.Text>{followers}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>{t('Price')}</Form.Label>
                  <div><Form.Text>{item.price}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="quantity">
                  <Form.Label>{t('Quantity')}</Form.Label>
                  <div><Form.Text>{item.quantity}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="paypalButtonWeb1">
                  <Form.Label>{t('Paypal Button Web 1 (US)')}</Form.Label>
                  <div>
                    {!!item.paypalButtonEmail1 && <a href={item.paypalButtonEmail1} target="_blank" rel="noreferrer"><img src={buyNowImg} alt="Buy now" /></a>}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="paypalButtonWeb2">
                  <Form.Label>{t('Paypal Button Web 2 (Other)')}</Form.Label>
                  <div>
                    {!!item.paypalButtonEmail2 && <a href={item.paypalButtonEmail2} target="_blank" rel="noreferrer"><img src={buyNowImg} alt="Buy now" /></a>}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="images">
                  <Form.Label>{t('Images')}</Form.Label>
                  {!!item?.images?.length && <div className="mb-2">
                    {item?.images.map((image:any, index:number) => (
                      <CloudinaryImage key={index} className="me-2 mb-2" image={image} width={100} height={100} />
                    ))}
                  </div>}
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="mb-5">
            <LinkContainer to={`/admin/raffles/edit/${item.id}`}><Button className="me-3">Edit</Button></LinkContainer>
            {item.status <= raffleStatuses.new.value && <Button variant="danger" onClick={handleDelete}>Delete</Button>}
            {item.status >= 1 && item.status < 10 && <Button variant="warning" onClick={handleDelayDelivery}>Delay Delivery</Button>}
          </div>
        </Tab>
        <Tab eventKey="raffle-register" title="People registered raffle">
          <RaffleRegisters id={id} />
        </Tab>
        <Tab eventKey="qr-codes" title="QR Codes Generator">
          <QRCodes id={id} raffle={item} />
        </Tab>
      </Tabs>
    </>
  )
}

export default Show
