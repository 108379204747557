import { getAuth, signOut } from 'firebase/auth'
import { IfFirebaseAuthed, IfFirebaseUnAuthed } from '@react-firebase/auth';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from '../resolveJs';
import { useRef } from 'react';
import { adminUids } from '../configs';
import logoImg from '../assets/img/header-logo.svg'

function Header() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toggleButtonRef = useRef<any>()

  const hideNavCollapse = () => {
    if (!toggleButtonRef.current.classList.contains('collapsed')) {
      toggleButtonRef.current.click()
    }
  }

  const handleLogout = (e:any) => {
    e.preventDefault()
    hideNavCollapse()
    signOut(getAuth()).then(() => {
      navigate('/')
      window.location.reload()
    })
  }

  return (
    <Navbar expand="lg" bg="black" variant="dark" fixed="top" className="bg-opacity-75">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand href="/">
            <img
              src={logoImg}
              width="200"
              height="90"
              className="d-inline-block align-top"
              alt="Enunicorn"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" ref={toggleButtonRef} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <LinkContainer to="/">
              <Nav.Link href="/" active={false} onClick={hideNavCollapse}>{t('Home')}</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/processing-raffles">
              <Nav.Link href="/processing-raffles" active={false} onClick={hideNavCollapse}>{t('Raffles')}</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/authenticity">
              <Nav.Link href="/authenticity" active={false} onClick={hideNavCollapse}>{t('Authenticity')}</Nav.Link>
            </LinkContainer>
            <IfFirebaseUnAuthed>
              {() => <>
                <LinkContainer to="/register"><Nav.Link href="/register" active={false} onClick={hideNavCollapse}>{t('Register')}</Nav.Link></LinkContainer>
                <LinkContainer to="/login"><Nav.Link href="/login" active={false} onClick={hideNavCollapse}>{t('Login')}</Nav.Link></LinkContainer>
              </>}
            </IfFirebaseUnAuthed>
            <IfFirebaseAuthed>
              {({user}) => <>
                <NavDropdown className="d-none d-lg-block" align="end" menuVariant="dark" title={(<>
                  <span>{user.displayName || 'User'}</span>
                </>)} id="user-nav-dropdown">
                  <LinkContainer to="/settings"><NavDropdown.Item active={false} href="/settings">{t('Settings')}</NavDropdown.Item></LinkContainer>
                  {adminUids.indexOf(user?.uid) >= 0 && <LinkContainer to="/admin/raffles"><NavDropdown.Item active={false} href="/admin/raffles">{t('Management')}</NavDropdown.Item></LinkContainer>}
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>{t('Logout')}</NavDropdown.Item>
                </NavDropdown>
                <LinkContainer to="/settings"><Nav.Link className="d-block d-lg-none" href="/settings" active={false} onClick={hideNavCollapse}>{t('Settings')}</Nav.Link></LinkContainer>
                {adminUids.indexOf(user?.uid) >= 0 && <LinkContainer to="/admin/raffles"><Nav.Link className="d-block d-lg-none" href="/admin/raffles" active={false} onClick={hideNavCollapse}>{t('Management')}</Nav.Link></LinkContainer>}
                <NavDropdown.Divider className="d-block d-lg-none"/>
                <Nav.Link className="d-block d-lg-none" onClick={handleLogout} active={false}>{t('Logout')}</Nav.Link>
              </>}
            </IfFirebaseAuthed>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
