import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "../resolveJs";

function PaymentExpiration() {
  const { t } = useTranslation()

  return (
    <Container className="my-5 py-5 d-flex justify-content-center align-items-center">
      <div className="my-5 py-5 text-center text-light">
        <h1>{t('Payment Expiration')}</h1>
        <p>{t('The payment period has expired. Enunicorn is sorry about this. Hope you will choose Enunicorn in the future.')}</p>
        <p>
          <LinkContainer to="/">
            <Button>{t('Go to Homepage')}</Button>
          </LinkContainer>
        </p>
      </div>
    </Container>
  );
}

export default PaymentExpiration;
