import moment from "moment"
import { getAuth } from "firebase/auth"
import { get, getDatabase, onValue, query, ref } from "firebase/database"
import { useEffect, useState } from "react"
import { Card, Carousel, Col, Container, ProgressBar, Row } from "react-bootstrap"
import CloudinaryImage from "./CloudinaryImage"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { adminUids } from "../configs"

function RaffleProcessingList() {
  const { t } = useTranslation()
  const [items, setItems] = useState<any>([])
  const [isTester, setIsTester] = useState<any>(false)

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const auth = getAuth()
      setIsTester(auth.currentUser && adminUids.indexOf(auth.currentUser.uid) >= 0)
      const db = getDatabase()
      const dbQuery = query(ref(db, 'raffleStatuses/running'))
      onValue(dbQuery, async (snapshot) => {
        if (!isSubscribed || !snapshot.exists()) {
          return
        }
        const raffles = Object.values(snapshot.val() || []).map((raffle: any) => ({
          ...raffle,
          ts: moment(raffle.time).unix(),
        })).sort((a: any, b: any) => b.ts - a.ts)
        const data: any = []
        let rfObj: any
        for (rfObj of raffles) {
          const raffleSnapshot = await get(query(ref(db, `raffles/${rfObj.id}`)))
          if (isSubscribed && raffleSnapshot.exists()) {
            data.push({
              ...raffleSnapshot.val(),
              id: rfObj.id,
            })
          }
        }
        setItems(data)
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [])

  const getProgressColor = (percent: number) => {
    if (percent <= 30) {
      return 'warning'
    } else if (percent <= 70) {
      return 'info'
    } else {
      return 'success'
    }
  }

  if (!items.filter((item: any) => !item.test || isTester).length) {
    return (
      <Container className="my-5 py-5 d-flex justify-content-center align-items-center">
        <div className="my-5 py-5 text-center text-light">
          <h2>{t('Coming Soon...')}</h2>
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        {!!items.length && items.map((raffle:any, index:number) => (!raffle.test || isTester) && (
          <Col sm={6} md={6} lg={4} key={index} className="mb-4">
            <Card bg="dark" text="light" className="overflow-hidden">
              <Carousel>
                {!!raffle.images?.length && raffle.images.map((image:any, index:number) => (
                  <Carousel.Item key={index}>
                    <div className="rounded">
                      <CloudinaryImage className="w-100" image={image} width={800} height={800} />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              <Card.Body>
                <Card.Title><Link className="text-light text-decoration-none" to={'/raffles/' + (raffle.alias || '')}>{raffle.name}</Link></Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{t('Colorway')}: {raffle.colorway}</Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">{t('Quantity')}: {raffle.quantity} {raffle.quantity === 1 ? 'keycap' : 'keycaps'}</Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">{t('Raffle Time')}: {moment(raffle.time).add(raffle.duration ?? 0, 'hours').format('YYYY/MM/DD HH:mm')}</Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">{t('Estimated Shipping Date')}: {moment(raffle.time).add(raffle.duration ?? 0, 'hours').add(1, 'day').format('YYYY/MM/DD')}</Card.Subtitle>
                <Card.Subtitle>
                  <ProgressBar animated variant={getProgressColor(raffle.progress)} now={raffle.progress || 0} label={`${raffle.progress || 0}%` + (raffle.progress === 100 ? t(' - Shipping') : '')} />
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default RaffleProcessingList;
