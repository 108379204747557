import {
  getAuth,
  sendEmailVerification,
} from "firebase/auth";
import { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Form
} from 'react-bootstrap'
import SubmitButton from '../components/SubmitButton'
import { useTranslation } from 'react-i18next'
import { toast } from "react-toastify";

function Verify() {
  const { t } = useTranslation()

  const [errors, setErrors] = useState<any>({});

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    try {
      const auth = getAuth()
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user)
        toast(t('Verification email has been sent to your mailbox'), { type: 'success' })
      }
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  };

  return <>
    <div className="my-5">&nbsp;</div>
    <Container className="mt-5 md-5 login-container">
      <Row className="d-flex justify-content-center">
        <Col xl={10} lg={12} md={9}>
          <Card className="bg-dark text-light overflow-hidden border-0">
            <Card.Body className="p-0">
              <Row>
                <Col className="d-none d-lg-block Art-Background"></Col>
                <Col className="p-5">
                  <h2>{t('Verify Account')}</h2>
                  <p>{t('Please check your email inbox and click on the verification link attached in the email')}</p>
                  <p>{t('If you do not see the verification email, please check your spam email box. Or click the button below to receive a new verification email')}</p>
                  <Form>
                    <div>{errors?.other?.message && (<Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text>)}</div>
                    <div className="d-grid gap-2 mt-3">
                      <SubmitButton variant="primary" href="/">
                        {t('Click if you have verified your email')}
                      </SubmitButton>
                    </div>
                    <div className="d-grid gap-2 mt-3">
                      <SubmitButton variant="link" onClick={handleSubmit}>
                        {t('Resend verification email')}
                      </SubmitButton>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
}

export default Verify;
