import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthLoginForm from "./AuthLoginForm";
import AuthRegisterForm from "./AuthRegisterForm";

interface AuthModalProps {
  show: boolean,
  onHide: () => void
}

function AuthModal({show, onHide}: AuthModalProps) {
  const { t } = useTranslation()
  const [showLogin, setShowLogin] = useState<boolean>(true)

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('Login to continue')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showLogin ?
          <AuthLoginForm onDone={() => onHide()} onRegisterLink={() => setShowLogin(false)} /> :
          <AuthRegisterForm onDone={() => onHide()} onLoginLink={() => setShowLogin(true)} />
        }
      </Modal.Body>
    </Modal>
  );
}

export default AuthModal;
