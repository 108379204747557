import * as Yup from 'yup'
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubmitButton from "./SubmitButton";
import { useState } from 'react';
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, updateProfile } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';
import { countries } from 'country-code-lookup'

interface AuthModalProps {
  onDone?: () => void,
  onLoginLink?: () => void
}

function AuthRegisterForm({ onDone, onLoginLink }: AuthModalProps) {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().min(6).required().label('Display Name'),
    email: Yup.string().min(6).email().required().label('Email'),
    phone: Yup.string().required().label('Phone'),
    address: Yup.string().required().label('Address'),
    country: Yup.string().required().label('Country'),
    password: Yup.string().min(6).required().label('Password'),
    confirmPassword: Yup.string().min(6).oneOf([Yup.ref('password'), null], 'Confirm Password and Password must match').required().label('Confirm Password'),
  })

  const [formData, setFormData] = useState({
    displayName: '',
    email: '',
    phone: '',
    address: '',
    country: '',
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState<any>({});

  const handleSetFormData = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    setErrors({})

    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err:any) {
      const errors:any = {}
      for (const error of err.inner) {
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }
    try {
      const data:any = { ...formData }
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password)
      await updateProfile(userCredential.user, {
        displayName: data.displayName
      });
      await sendEmailVerification(userCredential.user)
      await set(ref(getDatabase(), `users/${userCredential.user.uid}`), {
        email: data.email,
        displayName: data.displayName,
        address: data.address,
        country: data.country,
        phone: data.phone,
      })
      return () => onDone && onDone()
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="formRegisterEmail">
          <Form.Label>{t('Email address')}</Form.Label>
          <Form.Control type="email" name="email" value={formData.email} onChange={handleSetFormData} placeholder={t('Email address')} />
          {errors?.['email']?.message && (<Form.Text className="text-danger">{t(errors?.['email']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formRegisterDisplayName">
          <Form.Label>{t('Display Name')}</Form.Label>
          <Form.Control type="text" name="displayName" value={formData.displayName} onChange={handleSetFormData} placeholder={t('Display Name')} />
          {errors?.['displayName']?.message && (<Form.Text className="text-danger">{t(errors?.['displayName']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formRegisterPhone">
          <Form.Label>{t('Phone')}</Form.Label>
          <Form.Control type="text" name="phone" value={formData.phone} onChange={handleSetFormData} placeholder={t('Phone')} />
          {errors?.['phone']?.message && (<Form.Text className="text-danger">{t(errors?.['phone']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formRegisterAddress">
          <Form.Label>{t('Address')}</Form.Label>
          <Form.Control type="text" name="address" value={formData.address} onChange={handleSetFormData} placeholder={t('Address')} />
          {errors?.['address']?.message && (<Form.Text className="text-danger">{t(errors?.['address']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="country">
          <Form.Label>{t('Country')}</Form.Label>
          <Form.Select name="country" value={formData.country} onChange={handleSetFormData}>
            <option value="">{t('-- Select Country --')}</option>
            {countries.filter(country => country.isoNo !== "581").map(country => (
              <option key={country.internet} value={country.internet.toLowerCase()}>{t(country.country)}</option>
            ))}
            <option value="others">{t('Others')}</option>
          </Form.Select>
          {errors?.['country']?.message && (<Form.Text className="text-danger">{t(errors?.['country']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formRegisterPassword">
          <Form.Label>{t('Password')}</Form.Label>
          <Form.Control type="password" name="password" value={formData.password} onChange={handleSetFormData} placeholder={t('Password')} />
          {errors?.['password']?.message && (<Form.Text className="text-danger">{t(errors?.['password']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formRegisterConfirmPassword">
          <Form.Label>{t('Confirm Password')}</Form.Label>
          <Form.Control type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleSetFormData} placeholder={t('Password')} />
          {errors?.['confirmPassword']?.message && (<Form.Text className="text-danger">{t(errors?.['confirmPassword']?.message)}</Form.Text>)}
          {errors?.other?.message && (<Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text>)}
        </Form.Group>
        <div className="d-grid gap-2 mt-3">
          <SubmitButton variant="primary" type="submit" onClick={handleSubmit}>
            {t('Register')}
          </SubmitButton>
        </div>
        <hr/>
        <div className="text-center">
          <Button variant="link" onClick={onLoginLink}>{t('Already have an account? Login!')}</Button>
        </div>
      </Form>
    </>
  );
}

export default AuthRegisterForm;
