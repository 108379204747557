import * as Yup from 'yup'
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubmitButton from "./SubmitButton";
import { getAuth, updateProfile } from 'firebase/auth';
import { get, getDatabase, ref, set } from 'firebase/database';
import { toast } from 'react-toastify';
import { countries } from 'country-code-lookup'

interface ProfileModalProps {
  show: boolean,
  onHide: () => void
}

function ProfileModal({show, onHide}: ProfileModalProps) {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required().label('Display Name'),
    phone: Yup.string().required().label('Phone'),
    address: Yup.string().required().label('Address'),
    country: Yup.string().required().label('Country'),
  })

  const [formData, setFormData] = useState({
    displayName: '',
    phone: '',
    address: '',
    email: '',
    country: '',
  })
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user || !isSubscribed) {
        return
      }
      const dataSnapshot = await get(ref(getDatabase(), `users/${user.uid}`))
      if (!isSubscribed) {
        return
      }
      const profile = dataSnapshot.val()
      const data:any = {
        ...user,
        ...profile,
        phone: profile?.phone ?? '',
        address: profile?.address ?? '',
        country: profile?.country ?? '',
      }
      setFormData(data)
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [])

  const handleSetFormData = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    setErrors({})

    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err:any) {
      const errors:any = {}
      for (const error of err.inner) {
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }

    try {
      const data:any = { ...formData }
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) {
        throw new Error('Require login')
      }
      await updateProfile(user, {
        displayName: data.displayName
      });
      await set(ref(getDatabase(), `users/${user.uid}/email`), data.email)
      await set(ref(getDatabase(), `users/${user.uid}/displayName`), data.displayName)
      await set(ref(getDatabase(), `users/${user.uid}/phone`), data.phone)
      await set(ref(getDatabase(), `users/${user.uid}/address`), data.address)
      await set(ref(getDatabase(), `users/${user.uid}/country`), data.country)
      toast(t('Update profile successful'), { type: 'success' })
      return () => onHide && onHide()
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('Complete profile information to continue')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPhone">
            <Form.Label>{t('Phone')}</Form.Label>
            <Form.Control type="text" name="phone" value={formData.phone} onChange={handleSetFormData} placeholder={t('Phone')} />
            {errors?.['phone']?.message && (<Form.Text className="text-danger">{t(errors?.['phone']?.message)}</Form.Text>)}
          </Form.Group>
          <Form.Group className="mb-3" controlId="address">
            <Form.Label>{t('Address')}</Form.Label>
            <Form.Control type="text" name="address" value={formData.address} onChange={handleSetFormData} placeholder={t('Shipping Address')} />
            {errors?.['address']?.message && (<Form.Text className="text-danger">{t(errors?.['address']?.message)}</Form.Text>)}
          </Form.Group>
          <Form.Group className="mb-3" controlId="country">
            <Form.Label>{t('Shipping Country')}</Form.Label>
            <Form.Select name="country" value={formData.country} onChange={handleSetFormData}>
              <option value="">{t('-- Select Country --')}</option>
              {countries.filter(country => country.isoNo !== "581").map(country => (
                <option key={country.internet} value={country.internet.toLowerCase()}>{t(country.country)}</option>
              ))}
              <option value="others">{t('Others')}</option>
            </Form.Select>
            {errors?.['country']?.message && (<Form.Text className="text-danger">{t(errors?.['country']?.message)}</Form.Text>)}
          </Form.Group>
          {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
          <div className="d-grid gap-2 mt-5">
            <SubmitButton variant="primary" size="lg" type="submit" onClick={handleSubmit}>
              {t('Update')}
            </SubmitButton>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ProfileModal;
