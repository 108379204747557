import { useTranslation } from 'react-i18next'
import ShippingAddressForm from '../components/ShippingAddressForm'

function ShippingAddress() {
  const { t } = useTranslation()

  return (
    <>
      <div>
        <h2>{t('Shipping Address')}</h2>
        <ShippingAddressForm/>
      </div>
    </>
  )
}

export default ShippingAddress
