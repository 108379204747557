import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "../../../resolveJs";
import { get, getDatabase, ref, remove } from 'firebase/database';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import CloudinaryImage from "../../../components/CloudinaryImage";

function Show() {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [item, setItem] = useState<any>({})

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const db = getDatabase()
      const dbRef = ref(db, `products/${id}`)
      const dataSnapshot = await get(dbRef)
      if (!isSubscribed) {
        return
      }
      if (!dataSnapshot.size) {
        return navigate('/not-found')
      }
      setItem({
        id,
        ...dataSnapshot.val()
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [id, navigate])

  const handleDelete = (e:any) => {
    e.preventDefault()
    confirmAlert({
      closeOnClickOutside: false,
      title: t('Confirm to submit'),
      message: t('Are you sure to do this?'),
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            const db = getDatabase()
            await remove(ref(db, `products/${id}`))
            navigate('/admin/products')
          }
        },
        {
          label: t('No'),
          onClick: () => {}
        }
      ]
    })
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h2>{t('View Raffle')}</h2>
        <div>
          <LinkContainer to="/admin/products"><Button>{t('Back to List')}</Button></LinkContainer>
        </div>
      </div>
      <Form>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>{t('Name')}</Form.Label>
          <div><Form.Text>{item.name}</Form.Text></div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="image">
          <Form.Label>{t('Image')}</Form.Label>
          {!!item?.image && <div className="mb-2">
            <CloudinaryImage className="me-2 mb-2" image={item.image} width={100} height={100} />
          </div>}
        </Form.Group>
      </Form>
      <div className="mb-5">
        <LinkContainer to={`/admin/products/edit/${item.id}`}><Button className="me-3">Edit</Button></LinkContainer>
        <Button variant="danger" onClick={handleDelete}>Delete</Button>
      </div>
    </>
  )
}

export default Show
