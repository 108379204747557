import { getAuth } from 'firebase/auth';
import { get, getDatabase, ref, remove, set } from 'firebase/database';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthModal from './AuthModal';
import ProfileModal from './ProfileModal';
import SubmitButton from './SubmitButton';

interface FollowRaffleProps {
  raffle:any,
}

function FollowRaffle(props: FollowRaffleProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<any>({})
  const [followed, setFollowed] = useState<boolean>(false)
  const [openingStatus, setStatus] = useState<boolean>(props.raffle.time > moment().toISOString())
  const [authModalShow, setAuthModalShow] = useState<boolean>(false)
  const [profileModalShow, setProfileModalShow] = useState<boolean>(false)

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user || !openingStatus) {
        return
      }
      const db = getDatabase()
      const dbRef = ref(db, `raffleFollowers/${props.raffle.id}/users/${user.uid}`)
      const dataSnapshot = await get(dbRef)
      if (!isSubscribed) {
        return
      }
      setFollowed(!!dataSnapshot.val())
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [openingStatus, props.raffle.id])

  const handleSubmit = async (e:any) => {
    e.preventDefault()

    const auth = getAuth()
    const user = auth.currentUser
    if (!user) {
      return setAuthModalShow(true)
    }

    const profile = (await get(ref(getDatabase(), `users/${user.uid}`))).val()
    if (!profile || !profile.phone || !profile.address || !profile.address || !profile.country) {
      return setProfileModalShow(true)
    }

    confirmAlert({
      closeOnClickOutside: false,
      title: t(`Confirm to ${followed ? 'unfollow' : 'follow'}`),
      message: t(`Are you sure to ${followed ? 'unfollow' : 'follow'} this raffle?`),
      buttons: [
        {
          label: t('Yes'),
          onClick: () => {
            handleRegister()
          }
        },
        {
          label: t('No'),
          onClick: () => {}
        }
      ]
    })
  }

  const handleRegister = async () => {
    setErrors({})

    if (props.raffle.time <= moment().toISOString()) {
      setStatus(false)
      return
    }

    try {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) {
        return navigate('/login', { state: { registerRaffleId: props.raffle.id } })
      }

      const db = getDatabase()
      if (!followed) {
        await set(ref(db, `raffleFollowers/${props.raffle.id}/users/${user?.uid}/uid`), user?.uid)
        await set(ref(db, `raffleFollowers/${props.raffle.id}/users/${user?.uid}/displayName`), user?.displayName)
        await set(ref(db, `raffleFollowers/${props.raffle.id}/users/${user?.uid}/createdAt`), moment().toISOString())
        setFollowed(true)
      } else {
        await remove(ref(db, `raffleFollowers/${props.raffle.id}/users/${user?.uid}/uid`))
        await remove(ref(db, `raffleFollowers/${props.raffle.id}/users/${user?.uid}/displayName`))
        await remove(ref(db, `raffleFollowers/${props.raffle.id}/users/${user?.uid}/createdAt`))
        setFollowed(false)
      }
      toast(t('Successfully followed for the raffle'), { type: 'success' })
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  return (
    <>
      <SubmitButton variant={openingStatus ? (followed ? 'success' : 'warning') : 'danger'} size="lg" onClick={handleSubmit}>
        {openingStatus ? t(followed ? 'Unnotify Me' : 'Notify Me') : t('Raffle Closed')}
      </SubmitButton>
      {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
      <AuthModal show={authModalShow} onHide={() => setAuthModalShow(false)}/>
      <ProfileModal show={profileModalShow} onHide={() => setProfileModalShow(false)}/>
    </>
  );
}

export default FollowRaffle;
