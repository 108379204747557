import {
  Container,
} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import logoImg from '../assets/img/footer-logo.svg'

function Footer() {
  return (
    <div className="bg-black text-light py-5">
      <hr/>
      <Container>
        <div className="mt-5 d-flex justify-content-center">
          <Link to="/">
            <img
              src={logoImg}
              width="150"
              height="50"
              alt="Enunicorn"
            />
          </Link>
        </div>
        <div className="Text-Body-2 mb-3 d-flex justify-content-center">
          <a className="text-primary" href="mailto:contact.enunicorn@gmail.com">contact.enunicorn@gmail.com</a>
        </div>
        <div className="Text-Body-2 mb-3 d-flex justify-content-center">
          <a href="https://www.instagram.com/enunicorn_studio" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-instagram mx-2 SocialLink"></i>
          </a>
          <a href="https://www.facebook.com/enunicorn" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-facebook mx-2 SocialLink"></i>
          </a>
          <a href="https://discord.com/channels/1035493168252211251" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-discord mx-2 SocialLink"></i>
          </a>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
