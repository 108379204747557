import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "../../../resolveJs";
import { getDatabase, onValue, ref, remove } from 'firebase/database';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import CloudinaryImage from "../../../components/CloudinaryImage";
import buyNowImg from '../../../assets/img/buynow.gif'
import QRCodes from "./QRCodes";

function Show() {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [item, setItem] = useState<any>({})

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const db = getDatabase()
      const dbRef = ref(db, `groupBuys/${id}`)
      onValue(dbRef, (dataSnapshot) => {
        if (!isSubscribed) {
          return
        }
        if (!dataSnapshot.size) {
          return navigate('/not-found')
        }
        setItem({
          id,
          ...dataSnapshot.val()
        })
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [id, navigate])

  const handleDelete = (e:any) => {
    e.preventDefault()
    confirmAlert({
      closeOnClickOutside: false,
      title: t('Confirm to submit'),
      message: t('Are you sure to do this?'),
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            const db = getDatabase()
            await remove(ref(db, `groupBuys/${id}`))
            await remove(ref(db, `groupBuyAliases/${item.alias}`))
            navigate('/admin/group-buys')
          }
        },
        {
          label: t('No'),
          onClick: () => {}
        }
      ]
    })
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h2>{t('View Group By')}</h2>
        <div>
          <LinkContainer to="/admin/group-buys"><Button>{t('Back to List')}</Button></LinkContainer>
        </div>
      </div>
      <Tabs defaultActiveKey="info" className="mb-3">
        <Tab eventKey="info" title="Raffle Information">
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="id">
                  <Form.Label>{t('Id')}</Form.Label>
                  <div><Form.Text>{item.id}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>{t('Name')}</Form.Label>
                  <div><Form.Text>{item.name}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="alias">
                  <Form.Label>{t('Alias')}</Form.Label>
                  <div><Form.Text>{item.alias}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="colorway">
                  <Form.Label>{t('Colorway')}</Form.Label>
                  <div><Form.Text>{item.colorway}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>{t('Description')}</Form.Label>
                  <div><Form.Text>{item.description}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="time">
                  <Form.Label>{t('Upcoming Time')}</Form.Label>
                  <div><Form.Text>{moment(item.time).format('YYYY-MM-DD HH:mm:ss')}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="time">
                  <Form.Label>{t('Join Duration')}</Form.Label>
                  <div><Form.Text>{item.duration} hours</Form.Text></div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>{t('Price')}</Form.Label>
                  <div><Form.Text>{item.price}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="quantity">
                  <Form.Label>{t('Quantity')}</Form.Label>
                  <div><Form.Text>{item.quantity}</Form.Text></div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="paypalButtonWeb1">
                  <Form.Label>{t('Paypal Button Web 1 (US)')}</Form.Label>
                  <div>
                    {!!item.paypalButtonEmail1 && <a href={item.paypalButtonEmail1} target="_blank" rel="noreferrer"><img src={buyNowImg} alt="Buy now" /></a>}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="paypalButtonWeb2">
                  <Form.Label>{t('Paypal Button Web 2 (Other)')}</Form.Label>
                  <div>
                    {!!item.paypalButtonEmail2 && <a href={item.paypalButtonEmail2} target="_blank" rel="noreferrer"><img src={buyNowImg} alt="Buy now" /></a>}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="images">
                  <Form.Label>{t('Images')}</Form.Label>
                  {!!item?.images?.length && <div className="mb-2">
                    {item?.images.map((image:any, index:number) => (
                      <CloudinaryImage key={index} className="me-2 mb-2" image={image} width={100} height={100} />
                    ))}
                  </div>}
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="mb-5">
            <LinkContainer to={`/admin/group-buys/edit/${item.id}`}><Button className="me-3">Edit</Button></LinkContainer>
            {item.status <= 0 && <Button variant="danger" onClick={handleDelete}>Delete</Button>}
          </div>
        </Tab>
        <Tab eventKey="qr-codes" title="QR Codes Generator">
          <QRCodes id={id} groupBuy={item} />
        </Tab>
      </Tabs>
    </>
  )
}

export default Show
