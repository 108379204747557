import {
  Container,
  Row,
  Col,
  Card,
} from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AuthLoginForm from "../components/AuthLoginForm";

function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const handleAfterLogin = () => {
    const state:any = location.state
    navigate(state?.from?.pathname || '/', { replace: true })
  }

  return <>
    <div className="my-5">&nbsp;</div>
    <Container className="mt-5 md-5 login-container">
      <Row className="d-flex justify-content-center">
        <Col xl={10} lg={12} md={9}>
          <Card className="bg-dark text-light overflow-hidden border-0">
            <Card.Body className="p-0">
              <Row>
                <Col className="d-none d-lg-block Art-Background"></Col>
                <Col className="p-5">
                  <h2>{t('Welcome')}</h2>
                  <AuthLoginForm onDone={handleAfterLogin} onRegisterLink={() => navigate('/register')} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
}

export default Login;
