import { get, getDatabase, ref, set } from "firebase/database";
import { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "../resolveJs";

function PaymentSuccess() {
  const { t } = useTranslation()

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const paymentInfo = JSON.parse(localStorage.getItem('pinfo') || 'null')
      if (paymentInfo && paymentInfo.id && paymentInfo.orderId) {
        const { id, orderId } = paymentInfo
        const orderDetails = (await get(ref(getDatabase(), `raffleRegisters/${id}/users/${orderId}`))).val()
        if (!isSubscribed) {
          return
        }
        if (orderDetails) {
          await set(ref(getDatabase(), `raffleRegisters/${id}/users/${orderId}/requestCheckPayment`), true)
          localStorage.removeItem('pinfo')
        }
      }
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [])

  return (
    <Container className="my-5 py-5 d-flex justify-content-center align-items-center">
      <div className="my-5 py-5 text-center text-light">
        <h1>{t('Payment successful')}</h1>
        <p>{t('Your payment was successful. Hope you will choose Enunicorn in the future.')}</p>
        <p>
          <LinkContainer to="/">
            <Button>{t('Go to Homepage')}</Button>
          </LinkContainer>
        </p>
      </div>
    </Container>
  );
}

export default PaymentSuccess;
