import axios from "axios"

export const upload = async (file: any) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios({
    method: 'post',
    url: 'http://localhost:8080/upload.php',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData,
  })
}
