import * as Yup from 'yup'
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubmitButton from "./SubmitButton";
import { useState } from 'react';
import { getAdditionalUserInfo, getAuth, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';
import { toast } from 'react-toastify';

interface AuthModalProps {
  onDone?: () => void,
  onRegisterLink?: () => void
}

function AuthLoginForm({ onDone, onRegisterLink }: AuthModalProps) {
  const { t } = useTranslation()
  const [forgotPassword, setForgotPassword] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string().min(6).email().required().label('Email'),
    password: Yup.string().min(6).required().label('Password'),
  })

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState<any>({});

  const handleSetFormData = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    setErrors({})

    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err:any) {
      const errors:any = {}
      for (const error of err.inner) {
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }
    try {
      const data:any = { ...formData }
      const auth = getAuth()
      await signInWithEmailAndPassword(auth, data.email, data.password)
      return () => onDone && onDone()
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  const googleLoginHandle = async (e:any) => {
    e.preventDefault()
    try {
      const provider = new GoogleAuthProvider()
      const userCredential = await signInWithPopup(getAuth(), provider)
      const additionalUserInfo = getAdditionalUserInfo(userCredential)
      if (additionalUserInfo?.isNewUser) {
        await set(ref(getDatabase(), `users/${userCredential.user.uid}`), {
          email: userCredential.user.email,
          displayName: userCredential.user.displayName
        })
      }
      return () => onDone && onDone()
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  const handleForgotPassword = async (e:any) => {
    e.preventDefault()
    setErrors({})

    if (!formData.email) {
      const errors:any = {}
      errors['email'] = {
        message: 'Email is a required field'
      }
      setErrors(errors)
      return
    }

    try {
      const data:any = { ...formData }
      const auth = getAuth()
      await sendPasswordResetEmail(auth, data.email)
      toast(t('The password reset link has been sent to your email'), { type: 'success', autoClose: 8000 })
      return
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  if (forgotPassword) {
    return (
      <>
        <Form>
          <Form.Group className="mb-3" controlId="formLoginEmail">
            <Form.Label>{t('Email address')}</Form.Label>
            <Form.Control type="email" name="email" value={formData.email} onChange={handleSetFormData} placeholder={t('Email address')} />
            {errors?.['email']?.message && (<Form.Text className="text-danger">{t(errors?.['email']?.message)}</Form.Text>)}
          </Form.Group>
          {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
          <div className="d-grid gap-2 mt-3">
            <SubmitButton variant="primary" type="submit" onClick={handleForgotPassword}>
              {t('Forgot Password')}
            </SubmitButton>
          </div>
          <hr/>
          <div className="text-center">
            <Button variant="link" onClick={() => { setForgotPassword(false); setErrors({}) }}>{t('Remembered the password? Login!')}</Button>
          </div>
          <div className="text-center">
            <Button variant="link" onClick={onRegisterLink}>{t('Create an Account')}</Button>
          </div>
        </Form>
      </>
    )
  }

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="formLoginEmail">
          <Form.Label>{t('Email address')}</Form.Label>
          <Form.Control type="email" name="email" value={formData.email} onChange={handleSetFormData} placeholder={t('Email address')} />
          {errors?.['email']?.message && (<Form.Text className="text-danger">{t(errors?.['email']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formLoginPassword">
          <Form.Label>{t('Password')}</Form.Label>
          <Form.Control type="password" name="password" value={formData.password} onChange={handleSetFormData} placeholder={t('Password')} />
          {errors?.['password']?.message && (<Form.Text className="text-danger">{t(errors?.['password']?.message)}</Form.Text>)}
        </Form.Group>
        {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
        <div className="d-grid gap-2 mt-3">
          <SubmitButton variant="primary" type="submit" onClick={handleSubmit}>
            {t('Login')}
          </SubmitButton>
        </div>
        <hr/>
        <div className="d-grid gap-2 mt-3">
          <SubmitButton variant="danger" type="submit" onClick={googleLoginHandle}>
            {t('Login with Google')}
          </SubmitButton>
        </div>
        <hr/>
        <div className="text-center">
          <Button variant="link" onClick={() => { setForgotPassword(true); setErrors({}) }}>{t('Forgot password?')}</Button>
        </div>
        <div className="text-center">
          <Button variant="link" onClick={onRegisterLink}>{t('Create an Account')}</Button>
        </div>
      </Form>
    </>
  );
}

export default AuthLoginForm;
