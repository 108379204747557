import * as Yup from 'yup';
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../../components/SubmitButton";
import { DateTimePicker, LinkContainer } from "../../../resolveJs";
import { get, getDatabase, onValue, push, ref, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { upload } from '../../../utils';

function Create() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [files, setFiles] = useState<any>([])
  const [prices, setPrices] = useState<any>([])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    alias: Yup.string().required().matches(/^[a-z0-9-+]+$/, 'Only characters(a-z, 0-9, -, +) are allowed for this field').label('Alias'),
    colorway: Yup.string().required().label('Colorway'),
    description: Yup.string().label('Description'),
    time: Yup.date().required().label('Time'),
    quantity: Yup.number().label('Quantity'),
    duration: Yup.number().min(1).required().label('Duration'),
    price: Yup.number().required().label('Price'),
    images: Yup.array().label('Images'),
    test: Yup.boolean().label('Test'),
  })

  const [formData, setFormData] = useState({
    name: '',
    alias: '',
    colorway: '',
    description: 'From Enunicorn',
    time: moment().startOf('day'),
    quantity: 0,
    duration: 48,
    price: 0,
    images: [],
    test: false,
  })
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    let isSubscribed = true
    const fetchData = async () => {
      const db = getDatabase()
      const dbRef = ref(db, 'prices')
      onValue(dbRef, (snapshot) => {
        if (!isSubscribed || !snapshot.exists()) {
          return
        }
        setPrices(Object.values(snapshot.val()).filter((elm: any) => !isNaN(elm.price)))
      })
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [])

  const handleSetFormData = (e:any) => {
    if (e.target.type === 'checkbox') {
      return setFormData({
        ...formData,
        [e.target.name]: e.target.checked
      })
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    setErrors({})

    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err:any) {
      const errors:any = {}
      for (const error of err.inner) {
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }

    if (formData.alias) {
      const existsAlias: any = (await get(ref(getDatabase(), `groupBuyAliases/${formData.alias}`))).val()
      if (existsAlias) {
        return setErrors({
          alias: {
            message: t('This alias already exists')
          }
        })
      }
    }

    try {
      const data:any = { ...formData }
      data.price = parseInt(data.price)
      data.status = -1
      data.time = formData.time.toISOString()
      const price = prices.find((elm: any) => elm.price && data.price && elm.price.toString() === data.price.toString())
      if (price) {
        data.paypalButtonEmail1 = price.us
        data.paypalButtonEmail2 = price.other
      }
      if (data.images === undefined) {
        data.images = []
      }
      for (const file of files) {
        const result = await upload(file)
        data.images.push({
          public_id: result.data.data.public_id,
          secure_url: result.data.data.secure_url,
        })
      }
      formData.images = data.images
      setFiles([])
      const db = getDatabase()
      const newItemRef = push(ref(db, 'groupBuys'))

      // create group buy alias
      await set(ref(db, `groupBuyAliases/${data.alias}`), newItemRef.key)

      await set(newItemRef, data)
      return () => navigate('/admin/group-buys')
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h2>{t('Create Group Buy')}</h2>
        <div>
          <LinkContainer to="/admin/group-buys"><Button>{t('Back to List')}</Button></LinkContainer>
        </div>
      </div>
      <Form>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>{t('Name')}</Form.Label>
          <Form.Control type="text" name="name" value={formData.name} onChange={handleSetFormData} placeholder={t('Name')} />
          {errors?.['name']?.message && (<Form.Text className="text-danger">{t(errors?.['name']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="alias">
          <Form.Label>{t('Alias')}</Form.Label>
          <Form.Control type="text" name="alias" value={formData.alias} onChange={handleSetFormData} placeholder={t('Alias')} />
          {errors?.['alias']?.message && (<Form.Text className="text-danger">{t(errors?.['alias']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="colorway">
          <Form.Label>{t('Colorway')}</Form.Label>
          <Form.Control type="text" name="colorway" value={formData.colorway} onChange={handleSetFormData} placeholder={t('Colorway')} />
          {errors?.['colorway']?.message && (<Form.Text className="text-danger">{t(errors?.['colorway']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="description">
          <Form.Label>{t('Description')}</Form.Label>
          <Form.Control type="text" name="description" value={formData.description} onChange={handleSetFormData} placeholder={t('Description')} />
          {errors?.['description']?.message && (<Form.Text className="text-danger">{t(errors?.['description']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="price">
          <Form.Label>{t('Price')}</Form.Label>
          <Form.Select name="price" value={formData.price} onChange={handleSetFormData}>
            <option value={0}>- Select price -</option>
            {prices.map((price: any) => <option value={price.price} key={price.price}>${price.price}</option>)}
          </Form.Select>
          {errors?.['price']?.message && (<Form.Text className="text-danger">{t(errors?.['price']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="time">
          <Form.Label>{t('Upcoming Time')}</Form.Label>
          <DateTimePicker
            dateFormat="YYYY-MM-DD" value={formData.time}
            renderInput={(props:any) => <Form.Control {...props} placeholder={t('Time')}/>}
            onChange={(value: any) => handleSetFormData({target: {name: 'time', value}})}
          />
          {errors?.['time']?.message && (<Form.Text className="text-danger">{t(errors?.['time']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="duration">
          <Form.Label>{t('Join Duration (hour)')}</Form.Label>
          <Form.Control type="number" name="duration" value={formData.duration} onChange={handleSetFormData} placeholder={t('Duration')} />
          {errors?.['duration']?.message && (<Form.Text className="text-danger">{t(errors?.['duration']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="quantity">
          <Form.Label>{t('Quantity')}</Form.Label>
          <Form.Control type="number" name="quantity" value={formData.quantity} onChange={handleSetFormData} placeholder={t('Quantity')} />
          {errors?.['quantity']?.message && (<Form.Text className="text-danger">{t(errors?.['quantity']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="images">
          <Form.Label>{t('Images')}</Form.Label>
          <Form.Control type="file" name="images" multiple accept="image/*" onChange={(e:any) => setFiles(e.target.files)}/>
          {errors?.['image']?.message && (<Form.Text className="text-danger">{t(errors?.['image']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="test">
          <Form.Label>{t('Testing')}</Form.Label>
          <Form.Check type="switch" name="test" checked={formData.test} onChange={handleSetFormData} label={t('Group Buy for testing?')} />
          {errors?.['test']?.message && (<Form.Text className="text-danger">{t(errors?.['test']?.message)}</Form.Text>)}
        </Form.Group>
        {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
        <div className="d-grid gap-2 mt-5">
          <SubmitButton variant="primary" size="lg" type="submit" onClick={handleSubmit}>
            {t('Create')}
          </SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default Create
