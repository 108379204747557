import RaffleList from "../components/RaffleList"
import RaffleProcessingList from "../components/RaffleProcessingList"

function RaffleProcessing() {
  return <>
    <div className="my-5 pt-5">&nbsp;</div>
    <RaffleList hideHello/>
    <RaffleProcessingList />
  </>
}

export default RaffleProcessing
