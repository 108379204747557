import * as Yup from 'yup';
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../../components/SubmitButton";
import { LinkContainer } from "../../../resolveJs";
import { getDatabase, push, ref, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { upload } from '../../../utils';

function Create() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [file, setFile] = useState<any>(null)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    image: Yup.object().nullable().label('Image'),
  })

  const [formData, setFormData] = useState({
    name: '',
    image: null,
  })
  const [errors, setErrors] = useState<any>({})

  const handleSetFormData = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    setErrors({})

    try {
      await validationSchema.validate(formData, { abortEarly: false })
    } catch (err:any) {
      const errors:any = {}
      for (const error of err.inner) {
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }

    try {
      const data:any = { ...formData }
      if (file) {
        const result = await upload(file)
        data.image = {
          public_id: result.data.data.public_id,
          secure_url: result.data.data.secure_url,
        }
      }
      formData.image = data.image
      setFile(null)
      const db = getDatabase()
      const dbRef = ref(db, 'products')
      const newItemRef = push(dbRef)
      await set(newItemRef, data)
      return () => navigate('/admin/products')
    } catch (err:any) {
      const message = err.message ?? ''
      return setErrors({
        other: {
          path: 'other',
          message: message.replace('Firebase: ', '')
        }
      })
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h2>{t('Create Product')}</h2>
        <div>
          <LinkContainer to="/admin/products"><Button>{t('Back to List')}</Button></LinkContainer>
        </div>
      </div>
      <Form>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>{t('Name')}</Form.Label>
          <Form.Control type="text" name="name" value={formData.name} onChange={handleSetFormData} placeholder={t('Name')} />
          {errors?.['name']?.message && (<Form.Text className="text-danger">{t(errors?.['name']?.message)}</Form.Text>)}
        </Form.Group>
        <Form.Group className="mb-3" controlId="image">
          <Form.Label>{t('Image')}</Form.Label>
          <Form.Control type="file" name="image" accept="image/*" onChange={(e:any) => setFile(e.target.files[0])}/>
          {errors?.['image']?.message && (<Form.Text className="text-danger">{t(errors?.['image']?.message)}</Form.Text>)}
        </Form.Group>
        {errors?.other?.message && (<div className="mb-3"><Form.Text className="text-danger">{t(errors?.other?.message)}</Form.Text></div>)}
        <div className="d-grid gap-2 mt-5">
          <SubmitButton variant="primary" size="lg" type="submit" onClick={handleSubmit}>
            {t('Create')}
          </SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default Create
